import React from "react";
import { Card, Divider, Button, AspectImage } from "@theme-ui/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { formatPointString } from "../../app/context/points/formatting";
import { primary } from "../../app/DSL/theme";
import { useCart } from "../../app/context/cart";
import { useToasts } from "react-toast-notifications";

export default function ProductCard({ product, openAdd, openView }) {
  const { addToWishlist, wishlistItemIds, removeFromWishlist } = useCart();
  const { addToast } = useToasts();
  return (
    <ProdCard>
      <AspectImage
        ratio={1 / 1}
        src={
          product.productImageUrl
            ? product.productImageUrl
            : "/assets/product-placeholder.gif"
        }
      />
      <div className="description">
        <Divider />
        <h3>{product.name}</h3>
        <p>
          {product.stockType === "variable" && "From "}
          {formatPointString(product.minPrice)} Points
        </p>
      </div>
      <div>
        <Divider className="divider" />

        <ButtonGrid>
          {product.inStock ? (
            <Button onClick={() => openAdd(product)}>
              <FontAwesomeIcon icon={["fal", "cart-plus"]} /> Add to Cart
            </Button>
          ) : wishlistItemIds?.includes(product.id) ? (
            <Button
              onClick={() =>
                removeFromWishlist(product).then(() => {
                  addToast(`${product.name} removed from wishlist`, {
                    appearance: "success",
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                  });
                })
              }
            >
              <FontAwesomeIcon icon={["fas", "heart"]} /> Wishlisted
            </Button>
          ) : (
            <Button
              variant="outline"
              onClick={() =>
                addToWishlist(product).then((status) => {
                  const message =
                    status === "added"
                      ? `${product.name} added to wishlist`
                      : `${product.name} already in wishlist`;
                  addToast(message, {
                    appearance: status === "added" ? "success" : "warning",
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                  });
                })
              }
            >
              <FontAwesomeIcon icon={["fal", "heart"]} /> Add to wishlist
            </Button>
          )}

          <Button
            variant="secondary"
            onClick={() => openView(product)}
            className="mobile"
          >
            <FontAwesomeIcon icon={["fas", "eye"]} /> View
          </Button>

          <Button
            variant="secondary"
            onClick={() => openView(product)}
            className="not-mobile"
          >
            <FontAwesomeIcon icon={["fas", "eye"]} />
          </Button>
        </ButtonGrid>
      </div>
    </ProdCard>
  );
}

const ProdCard = styled(Card)`
  display: grid;
  grid-template-rows: auto auto 60px;
  .divider {
    margin-bottom: 1em;
  }
  .description {
    display: grid;
    p {
      align-self: end;
      color: ${(props) => props.theme.colors[primary].shade["500"]};
    }
  }
  @media (max-width: 768px) {
    grid-template-rows: auto auto auto;
  }
`;

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1em;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;
