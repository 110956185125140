import React from "react";
import release from "../../../config/release.json";
import styled from "@emotion/styled";
import { Image } from "@theme-ui/components";

//TODO: add env variables for new instance

export default function Footer() {
  const now = new Date();
  return (
    <Grid>
      <small>Version {release.versionClient}</small>
      <div>
        <p>
          © {now.getFullYear()}. Reconise is a Zimmer Biomet Southern African
          Initiative.
        </p>
        <p>
          <a
            href="https://reconise.com/terms/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>{" "}
          apply
        </p>
      </div>

      <Image src="/assets/ZBFullLogo.png" width="200px" />
    </Grid>
  );
}

const Grid = styled.div`
  z-index: 0;
  display: grid;
  grid-template-columns: 200px 1fr 200px;
  column-gap: 1em;
  align-self: center;
  align-items: center;
  justify-items: center;
  filter: grayscale(1);
  opacity: 0.6;
  padding: 2em 0;
  * {
    color: ${(props) => props.theme.colors.midnight.shade[100]};
  }
  small {
    display: block;
    width: 100%;
  }
  p {
    margin: 0;
    text-align: center;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
    small {
      text-align: center;
    }
  }
`;
