import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faSignOut,
  faCheck,
  faAngleDown,
  faAngleUp,
  faSpinner,
  faFolderOpen,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faTrash,
  faCircle,
  faTh,
  faStar,
  faNewspaper,
  faSearch,
  faTimes,
  faExclamationCircle,
  faStoreAlt,
  faHistory,
  faBoxes,
  faLightbulbOn,
  faCartPlus,
  faBan,
  faHeart,
} from "@fortawesome/pro-light-svg-icons";

library.add(
  faSignOut,
  faCheck,
  faAngleDown,
  faAngleUp,
  faSpinner,
  faFolderOpen,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faTrash,
  faCircle,
  faTh,
  faStar,
  faNewspaper,
  faSearch,
  faTimes,
  faExclamationCircle,
  faLightbulbOn,
  faStoreAlt,
  faHistory,
  faBoxes,
  faCartPlus,
  faBan,
  faHeart
);

export {
  faSignOut,
  faCheck,
  faAngleDown,
  faAngleUp,
  faSpinner,
  faFolderOpen,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faTrash,
  faCircle,
  faTh,
  faStar,
  faNewspaper,
  faSearch,
  faTimes,
  faExclamationCircle,
  faLightbulbOn,
  faStoreAlt,
  faHistory,
  faBoxes,
  faCartPlus,
  faBan,
  faHeart,
};
