import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCog,
  faTh,
  faTrash,
  faPencilAlt,
  faEye,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faCheck,
  faLock,
  faHeart,
  faShoppingCart,
  faQuestionCircle,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

import { faSignOut, faPencil } from "@fortawesome/pro-solid-svg-icons";

library.add(
  faSignOut,
  faCog,
  faTh,
  faTrash,
  faPencilAlt,
  faEye,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faCheck,
  faLock,
  faHeart,
  faShoppingCart,
  faPencil,
  faQuestionCircle,
  faStar
);

export {
  faSignOut,
  faCog,
  faTh,
  faTrash,
  faPencilAlt,
  faEye,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faCheck,
  faLock,
  faHeart,
  faShoppingCart,
  faPencil,
  faQuestionCircle,
  faStar,
};
