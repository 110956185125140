import React, { useMemo, useState, useCallback, useEffect } from "react";
import { useProducts } from "../../../app/context/products";
import { getObjFromLink } from "../../../app/context/helpers";
import { Card, Divider, AspectImage } from "@theme-ui/components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductModal from "../../Products/ProductModal";
import { NavLink } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  ActionsTableContainer,
  RoundButton,
  SeeAllLink,
  StyledLabel,
} from "../../../app/DSL/components/elements/styled";
import Table from "../../../app/DSL/components/elements/Table";
import ReactTooltip from "react-tooltip";
import { useOrders } from "../../../app/context/orders";
import styled from "@emotion/styled";
import { primary } from "../../../app/DSL/theme";

export default function OrderedProducts() {
  const [modalItem, setModalItem] = useState();
  const [modalOpen, setModalOpen] = useState();
  const { products, updateProduct } = useProducts();
  const { activeOrderedItems } = useOrders();
  const { addToast } = useToasts();
  useEffect(() => {
    console.log(
      activeOrderedItems.map((i) => getObjFromLink(i.productLink).id)
    );
    console.log(
      products?.filter((p) =>
        activeOrderedItems
          .map((i) => getObjFromLink(i.productLink).id)
          .includes(p.id)
      )
    );
  }, [products, activeOrderedItems]);
  const productsList = useMemo(
    () =>
      products
        ? products
            .filter((p) => !p.isArchived)
            .sort((a, b) => {
              const cartItemA = activeOrderedItems?.find(
                (c) => getObjFromLink(c.productLink).id === a.id
              );
              const countA = cartItemA?.count ? cartItemA?.count : 0;

              const cartItemB = activeOrderedItems?.find(
                (c) => getObjFromLink(c.productLink).id === b.id
              );
              const countB = cartItemB?.count ? cartItemB?.count : 0;

              return countB - countA;
            })
            .slice(0, 5)
        : [],
    [products, activeOrderedItems]
  );

  const toggleFeatured = useCallback(
    (id, featured) => {
      updateProduct(id, { isFeatured: !featured }).then(() =>
        addToast(
          featured
            ? "Product removed from featured list"
            : "Product added to featured list",
          {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 3000,
          }
        )
      );
    },
    [addToast, updateProduct]
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        id: "img",
        accessor: (p) => (
          <div style={{ width: "50px" }}>
            <AspectImage
              ratio={1 / 1}
              src={
                p.productImageUrl
                  ? p.productImageUrl
                  : "/assets/product-placeholder.gif"
              }
            />
          </div>
        ),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Name",
        id: "name",
        accessor: (p) => p.name,
        Cell: (cell) => <h4>{cell.row.original.name}</h4>,
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },

      {
        Header: "In Open Orders",
        id: "ordered",
        accessor: (p) => {
          const item = activeOrderedItems?.find(
            (c) => getObjFromLink(c.productLink).id === p.id
          );
          return item?.count ? item?.count : 0;
        },
        Cell: (cell) => {
          const item = activeOrderedItems?.find(
            (c) => getObjFromLink(c.productLink).id === cell.row.original.id
          );
          return (
            <Points>
              <p className="order">
                <FontAwesomeIcon icon={["far", "shopping-bag"]} size="xs" />{" "}
                {item?.count ? item?.count : 0}
              </p>
            </Points>
          );
        },
        filter: "fuzzyText",
        disableFilters: true,
        disableSortBy: true,
      },

      {
        Header: "Actions",
        accessor: (u) => u.id,
        Cell: (cell) => {
          return (
            <span>
              <RoundButton
                data-tip="View Product"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  setModalOpen(true);
                  setModalItem(cell.row.original);
                }}
              >
                <FontAwesomeIcon icon={["fas", "eye"]} />
              </RoundButton>

              <RoundButton
                data-tip="Feature Product"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  toggleFeatured(
                    cell.row.original.id,
                    cell.row.original.isFeatured
                  );
                }}
              >
                <FontAwesomeIcon
                  icon={[cell.row.original.isFeatured ? "fas" : "far", "star"]}
                />
              </RoundButton>

              <ReactTooltip id="actionName" effect="solid" />
            </span>
          );
        },
        className: "actions",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [activeOrderedItems, toggleFeatured]
  );

  return (
    <Card style={{ position: "relative" }}>
      <BlockHeaderContainer title="Most ordered products"></BlockHeaderContainer>
      <div style={{ marginBottom: "3em" }}>
        <ActionsTableContainer numActions={2}>
          <Table
            emptyMsg="No products"
            columns={columns}
            data={productsList}
            defaultRows={5}
            loading={products === undefined}
            pagination={false}
            filterPlaceholder={false}
          />
        </ActionsTableContainer>
      </div>
      <SeeAllLink>
        <Divider />
        <NavLink to="/admin-area/products">See all</NavLink>
      </SeeAllLink>
      <ProductModal
        product={modalItem}
        close={() => setModalOpen(false)}
        viewOnly={true}
        open={modalOpen}
      />
    </Card>
  );
}

const Points = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 5px;

  .cart {
    svg {
      color: ${(props) => props.theme.colors[primary].shade["500"]};
    }
  }

  .wishlist {
    svg {
      color: ${(props) => props.theme.colors.warning.shade["500"]};
    }
  }
  .order {
    svg {
      color: ${(props) => props.theme.colors.lightGreen.shade["500"]};
    }
  }

  p {
    font-size: 1.2em;
  }
`;
