import React from "react";
import { InputWrap } from "./StyledInput";
import { Label } from "@theme-ui/components";

export default function Field({ name, values, error, children, title, type }) {
  return (
    <span style={{ gridArea: name }}>
      <InputWrap
        state={
          ((error || values[name].touched) &&
            !values[name].valid &&
            values[name].blur &&
            "error") ||
          (error &&
            error.type === "existingUser" &&
            name === "email" &&
            "error")
        }
      >
        <Label htmlFor={name}>{title}</Label>
        {children}
        {type !== "radio" &&
          ((error || values[name].touched) &&
          !values[name].valid &&
          values[name].blur ? (
            <Label variant="infoLabel.warning">{values[name].error}</Label>
          ) : (
            <Label variant="infoLabel.hidden">...</Label>
          ))}
      </InputWrap>
    </span>
  );
}
