import React from "react";
import CardModal from "../../app/DSL/components/elements/CardModal";
import { Heading, Button } from "@theme-ui/components";
import { formatPointString } from "../../app/context/points/formatting";
import { useUserContext } from "../../app/context/auth";

export default function ConfirmCheckoutModal({
  totalPrice,
  open,
  close,
  placeOrder,
}) {
  const user = useUserContext();
  return (
    <CardModal isOpen={open} close={close} width="450px">
      <Heading>Are you sure you're ready to checkout?</Heading>
      <h1>Cart total : {formatPointString(totalPrice)} points</h1>
      <div>
        <p>
          You will have {formatPointString(user?.points - totalPrice)} points
          after checkout
        </p>
        <Button onClick={() => placeOrder()}>Checkout</Button>
      </div>
    </CardModal>
  );
}
