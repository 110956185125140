import React, { useMemo, useState, useCallback } from "react";
import {
  ActionsTableContainer,
  RoundButton,
  StyledLabel,
} from "../../../app/DSL/components/elements/styled";
import Table from "../../../app/DSL/components/elements/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { Checkbox, Label, AspectImage } from "@theme-ui/components";
import { useProducts } from "../../../app/context/products";
import ProductModal from "../../Products/ProductModal";
import ConfirmationModal from "../../../app/DSL/components/elements/ConfirmationModal";
import { useToasts } from "react-toast-notifications";
import EditProductModal from "./EditProductModal";
import { useCarts } from "../../../app/context/carts";
import { getObjFromLink } from "../../../app/context/helpers";
import styled from "@emotion/styled";
import { primary } from "../../../app/DSL/theme";
import { useOrders } from "../../../app/context/orders";

export default function ProductsTable({ selectedProducts, toggleSelected }) {
  const { products, archiveProducts, updateProduct } = useProducts();
  const { items } = useCarts();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalItem, setModalItem] = useState();
  const [modalType, setModalType] = useState();
  const { addToast } = useToasts();
  const { activeOrderedItems } = useOrders();

  const productsList = useMemo(
    () =>
      products
        ? products
            .filter((p) => !p.isArchived)
            .sort((a, b) => {
              const cartItemA = items?.find(
                (c) => getObjFromLink(c.productLink).id === a.id
              );
              const countA = cartItemA?.wishlistCount
                ? cartItemA?.wishlistCount
                : 0;
              const cartItemB = items?.find(
                (c) => getObjFromLink(c.productLink).id === b.id
              );
              const countB = cartItemB?.wishlistCount
                ? cartItemB?.wishlistCount
                : 0;
              return countB - countA;
            })
        : [],
    [products, items]
  );

  const archive = (product) => {
    return archiveProducts([product.id]).then(() => {
      addToast(`Product Archived`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      setModalOpen(false);
    });
  };

  const toggleFeatured = useCallback(
    (id, featured) => {
      updateProduct(id, { isFeatured: !featured }).then(() =>
        addToast(
          featured
            ? "Product removed from featured list"
            : "Product added to featured list",
          {
            appearance: "success",
            autoDismiss: true,
            autoDismissTimeout: 3000,
          }
        )
      );
    },
    [addToast, updateProduct]
  );
  //TODO: move columns to separate file
  const columns = useMemo(
    () => [
      {
        Header: "",
        id: "check",
        accessor: (o) => o,
        Cell: (cell) => {
          return (
            <Label>
              <Checkbox
                checked={selectedProducts.includes(cell.row.original.id)}
                onChange={(e) => {
                  toggleSelected(cell.row.original.id);
                }}
              />
            </Label>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "",
        id: "img",
        accessor: (p) => (
          <AspectImage
            ratio={1 / 1}
            src={
              p.productImageUrl
                ? p.productImageUrl
                : "/assets/product-placeholder.gif"
            }
          />
        ),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "Product Name",
        id: "name",
        accessor: (p) => p.name,
        Cell: (cell) => <h4>{cell.row.original.name}</h4>,
        filter: "fuzzyText",
      },

      {
        Header: "Price",
        id: "price",
        accessor: (p) => p.minPrice,
        Cell: (cell) => (
          <h4>
            {cell.row.original.stockType === "variable" && "From "}
            {cell.row.original.minPrice} Points
          </h4>
        ),
        filter: "fuzzyText",
      },
      {
        Header: "In Carts",
        id: "carts",
        accessor: (p) => {
          const cartItem = items?.find(
            (c) => getObjFromLink(c.productLink).id === p.id
          );
          return cartItem?.cartCount ? cartItem?.cartCount : 0;
        },
        Cell: (cell) => {
          const cartItem = items?.find(
            (c) => getObjFromLink(c.productLink).id === cell.row.original.id
          );
          return (
            <Points>
              <p className="cart">
                <FontAwesomeIcon icon={["far", "shopping-cart"]} size="lg" />{" "}
                {cartItem?.cartCount ? cartItem?.cartCount : 0}
              </p>
            </Points>
          );
        },
        filter: "fuzzyText",
      },
      {
        Header: "In Wishlists",
        id: "wishlists",
        accessor: (p) => {
          const cartItem = items?.find(
            (c) => getObjFromLink(c.productLink).id === p.id
          );
          return cartItem?.wishlistCount ? cartItem?.wishlistCount : 0;
        },
        Cell: (cell) => {
          const cartItem = items?.find(
            (c) => getObjFromLink(c.productLink).id === cell.row.original.id
          );
          return (
            <Points>
              <p className="wishlist">
                <FontAwesomeIcon icon={["far", "heart"]} size="lg" />{" "}
                {cartItem?.wishlistCount ? cartItem?.wishlistCount : 0}
              </p>
            </Points>
          );
        },
        filter: "fuzzyText",
      },
      {
        Header: "In Open Orders",
        id: "ordered",
        accessor: (p) => {
          const item = activeOrderedItems?.find(
            (c) => getObjFromLink(c.productLink).id === p.id
          );
          return item?.count ? item?.count : 0;
        },
        Cell: (cell) => {
          const item = activeOrderedItems?.find(
            (c) => getObjFromLink(c.productLink).id === cell.row.original.id
          );
          return (
            <Points>
              <p className="order">
                <FontAwesomeIcon icon={["far", "shopping-bag"]} size="lg" />{" "}
                {item?.count ? item?.count : 0}
              </p>
            </Points>
          );
        },
        filter: "fuzzyText",
      },
      {
        Header: "Actions",
        accessor: (u) => u.id,
        Cell: (cell) => {
          return (
            <span>
              <RoundButton
                data-tip="View Product"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  setModalOpen(true);
                  setModalItem(cell.row.original);
                  setModalType("view");
                }}
              >
                <FontAwesomeIcon icon={["fas", "eye"]} />
              </RoundButton>
              <RoundButton
                data-tip="Edit Product"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  setModalOpen(true);
                  setModalItem(cell.row.original);
                  setModalType("update");
                }}
              >
                <FontAwesomeIcon icon={["fas", "pencil"]} />
              </RoundButton>

              <RoundButton
                data-tip="Archive product"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  setModalOpen(true);
                  setModalItem(cell.row.original);
                  setModalType("confirm");
                }}
              >
                <FontAwesomeIcon icon={["fas", "trash"]} />
              </RoundButton>
              <RoundButton
                data-tip="Feature Product"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  toggleFeatured(
                    cell.row.original.id,
                    cell.row.original.isFeatured
                  );
                }}
              >
                <FontAwesomeIcon
                  icon={[cell.row.original.isFeatured ? "fas" : "far", "star"]}
                />
              </RoundButton>

              <ReactTooltip id="actionName" effect="solid" />
            </span>
          );
        },
        className: "actions",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [
      selectedProducts,
      toggleSelected,
      items,
      toggleFeatured,
      activeOrderedItems,
    ]
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: "",
        id: "check",
        accessor: (o) => o,
        Cell: (cell) => {
          return (
            <Label>
              <Checkbox
                checked={selectedProducts.includes(cell.row.original.id)}
                onChange={(e) => {
                  toggleSelected(cell.row.original.id);
                }}
              />
            </Label>
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },

      {
        Header: "Product Name",
        id: "name",
        accessor: (p) => p.name,
        Cell: (cell) => <h4>{cell.row.original.name}</h4>,
        filter: "fuzzyText",
      },
      {
        Header: "Actions",
        accessor: (u) => u.id,
        Cell: (cell) => {
          return (
            <span>
              <RoundButton
                data-tip="View Product"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  setModalOpen(true);
                  setModalItem(cell.row.original);
                  setModalType("view");
                }}
              >
                <FontAwesomeIcon icon={["fas", "eye"]} />
              </RoundButton>
              <RoundButton
                data-tip="Edit Product"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  setModalOpen(true);
                  setModalItem(cell.row.original);
                  setModalType("update");
                }}
              >
                <FontAwesomeIcon icon={["fas", "pencil"]} />
              </RoundButton>

              <RoundButton
                data-tip="Archive product"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  setModalOpen(true);
                  setModalItem(cell.row.original);
                  setModalType("confirm");
                }}
              >
                <FontAwesomeIcon icon={["fas", "trash"]} />
              </RoundButton>
              <RoundButton
                data-tip="Feature Product"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  toggleFeatured(
                    cell.row.original.id,
                    cell.row.original.isFeatured
                  );
                }}
              >
                <FontAwesomeIcon
                  icon={[cell.row.original.isFeatured ? "fas" : "far", "star"]}
                />
              </RoundButton>

              <ReactTooltip id="actionName" effect="solid" />
            </span>
          );
        },
        className: "actions",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [selectedProducts, toggleSelected, toggleFeatured]
  );

  return (
    <div>
      <div className="not-mobile">
        <ActionsTableContainer selectable numActions={4}>
          <Table
            selectedItems={selectedProducts}
            toggleSelected={toggleSelected}
            emptyMsg="No products"
            columns={columns}
            data={productsList}
            defaultRows={5}
            loading={products === undefined}
          />
        </ActionsTableContainer>
      </div>
      <div className="mobile">
        <ActionsTableContainer selectable numActions={2}>
          <Table
            selectedItems={selectedProducts}
            toggleSelected={toggleSelected}
            emptyMsg="No products"
            columns={mobileColumns}
            data={productsList}
            defaultRows={5}
            loading={products === undefined}
          />
        </ActionsTableContainer>
      </div>
      <ProductModal
        viewOnly={true}
        product={modalItem}
        open={modalOpen && modalType === "view"}
        close={() => {
          setModalOpen(false);
          setModalItem(null);
        }}
      />
      <ConfirmationModal
        setOpen={setModalOpen}
        isOpen={modalOpen && modalType === "confirm"}
        actionType="archive"
        action={archive}
        item={modalItem}
        name={modalItem?.name}
      />
      <EditProductModal
        open={modalOpen && modalType === "update"}
        close={() => setModalOpen(false)}
        product={modalItem}
      />
    </div>
  );
}

const Points = styled.div`
  svg {
    margin-right: 5px;
  }
  .cart {
    svg {
      color: ${(props) => props.theme.colors[primary].shade["500"]};
    }
    padding-right: 1em;
  }

  .wishlist {
    svg {
      color: ${(props) => props.theme.colors.warning.shade["500"]};
    }
    padding-right: 1em;
  }
  .order {
    svg {
      color: ${(props) => props.theme.colors.lightGreen.shade["500"]};
    }
    padding-right: 1em;
  }

  p {
    font-size: 1.2em;
  }
`;
