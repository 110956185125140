import { isBefore } from "date-fns";

export const formatOrder = ({ items, user, lastOrder, totalPrice }) => {
  return {
    items,
    placedBy: { id: user.id, email: user.email, name: user.displayName },
    userLink: `${user.id}__${user.displayName}`,
    dateCreated: new Date(),
    dateUpdated: new Date(),
    status: "new",
    orderNumber: lastOrder?.orderNumber ? lastOrder.orderNumber + 1 : 1,
    totalPrice,
    deliveryDate: deliveryDate(),
  };
};

const deliveryDate = () => {
  const now = new Date();
  const cutOff = new Date();
  cutOff.setDate(24);
  cutOff.setHours(0, 0, 0, 0);
  const thisMonth = isBefore(now, cutOff);
  const orderMonth = now.getMonth();
  const delivery = new Date();
  delivery.setDate(25);
  delivery.setMonth(thisMonth ? orderMonth + 1 : orderMonth + 2);
  return delivery;
};

export const formatUpdateOrder = (order, user) => {
  return {
    ...order,
    dateUpdated: new Date(),
    updatedBy: { id: user.id, email: user.email, name: user.displayName },
  };
};
