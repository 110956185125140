import React, { useState } from "react";
import {
  TableContainer,
  RoundButton,
  StyledLabel,
} from "../../app/DSL/components/elements/styled";
import { getObjFromLink } from "../../app/context/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCart } from "../../app/context/cart";
import { Image, Flex } from "@theme-ui/components";
import ConfirmationModal from "../../app/DSL/components/elements/ConfirmationModal";
import { useToasts } from "react-toast-notifications";
import TransferModal from "./TransferModal";
import MobileCard from "./MobileCard";
import ReactTooltip from "react-tooltip";

export default function WishlistTable() {
  const { wishlist, removeFromWishlist } = useCart();

  const [modalOpen, setModalOpen] = useState(false);
  const [modal, setModal] = useState("");
  const [modalAction, setModalAction] = useState();
  const [modalActionType, setModalActionType] = useState();
  const [confirmItem, setConfirmItem] = useState({});

  const { addToast } = useToasts();

  const removeItem = (item) => {
    removeFromWishlist(item).then(() => {
      setModalOpen(false);
      addToast(
        `${getObjFromLink(item.productLink).name} removed from wishlist`,
        {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        }
      );
    });
  };

  return (
    <div>
      <div className="mobile">
        {wishlist?.items?.map((i, idx) => (
          <MobileCard
            key={idx}
            item={i}
            removeItem={removeItem}
            setModalOpen={setModalOpen}
            setModal={setModal}
            setModalAction={setModalAction}
            setModalActionType={setModalActionType}
            setConfirmItem={setConfirmItem}
          />
        ))}
      </div>
      <TableContainer className="not-mobile">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th style={{ textAlign: "center" }}>Status</th>
              <th style={{ textAlign: "center" }}>Price</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {wishlist?.items?.map((i, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <Image
                      src={
                        i.productImageUrl
                          ? i.productImageUrl
                          : "/assets/product-placeholder.gif"
                      }
                      variant="cartPlaceholder"
                    />
                  </td>
                  <td>{getObjFromLink(i.productLink).name}</td>
                  <td style={{ textAlign: "center" }}>
                    <StyledLabel
                      colour={
                        i.discontinued
                          ? "tusk"
                          : i.inStock
                          ? "lightGreen"
                          : "warning"
                      }
                      textColour="white"
                    >
                      {i.discontinued
                        ? "DISCONTINUED"
                        : i.inStock
                        ? "IN STOCK"
                        : "OUT OF STOCK"}
                    </StyledLabel>
                  </td>
                  <td style={{ textAlign: "center" }}>{i.price}</td>
                  <td>
                    <Flex>
                      {i.inStock && !i.discontinued && (
                        <RoundButton
                          data-tip="Transfer to cart"
                          data-for="actionName"
                          onClick={() => {
                            setModalOpen(true);
                            setConfirmItem(i);
                            setModal("transfer");
                          }}
                          style={{
                            marginRight: "1em",
                          }}
                          variant="secondary"
                        >
                          <FontAwesomeIcon
                            icon={["fas", "shopping-cart"]}
                            size="lg"
                          />
                        </RoundButton>
                      )}
                      <RoundButton
                        data-tip="Remove from wishlist"
                        data-for="actionName"
                        variant="secondary"
                        onClick={() => {
                          setModalOpen(true);
                          setModalActionType("remove");
                          setModalAction(() => removeItem);
                          setConfirmItem(i);
                          setModal("confirm");
                        }}
                      >
                        <FontAwesomeIcon icon={["fal", "times"]} size="lg" />
                      </RoundButton>
                      <ReactTooltip id="actionName" effect="solid" />
                    </Flex>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableContainer>
      <ConfirmationModal
        width="450px"
        isOpen={modalOpen && modal === "confirm"}
        setOpen={setModalOpen}
        action={modalAction}
        actionType={modalActionType}
        item={confirmItem}
      />

      <TransferModal
        item={confirmItem}
        open={modalOpen && modal === "transfer"}
        close={() => {
          setModalOpen(false);
          setConfirmItem(null);
        }}
      />
    </div>
  );
}
