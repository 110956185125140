import React, { useState } from "react";
import CardModal from "../../../app/DSL/components/elements/CardModal";
import ProductForm from "./ProductForm/index.jsx";
import { useProducts } from "../../../app/context/products";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import ProductImage from "./ProductForm/ProductImage";
import { useToasts } from "react-toast-notifications";

export default function CreateProductModal({ open, close }) {
  const { createProduct, updateProduct } = useProducts();
  const [loading, setLoading] = useState(false);
  //TODO: use error
  //const [error, setError] = useState();
  const [step, setStep] = useState(1);
  const [prodId, setProdId] = useState("");
  const { addToast } = useToasts();
  const submit = (e, formattedValues, reset) => {
    console.log("hello");
    e.preventDefault();
    createProduct(formattedValues).then(({ id }) => {
      console.log(id);
      setProdId(id);
      reset();
      setStep(2);
    });
  };
  const submitImage = (url, createAnother) => {
    setLoading(true);
    updateProduct(prodId, { productImageUrl: url })
      .then(() => {
        addToast(`Product Created`, {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
        setLoading(false);
        setStep(1);
        if (!createAnother) {
          close();
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <CardModal
      isOpen={open}
      close={() => {
        close();
        setStep(1);
      }}
    >
      <BlockHeaderContainer title="Create new product" />
      {step === 1 && (
        <ProductForm
          submit={submit}
          loading={loading}
          error={false}
          submitText="Next"
          cancel={close}
          create={true}
        />
      )}
      {step === 2 && (
        <ProductImage
          close={close}
          submitImage={submitImage}
          submitText="Create Product"
          create={true}
          loading={loading}
        />
      )}
    </CardModal>
  );
}
