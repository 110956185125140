import React, { useState } from "react";
import ImageUpload from "../../../../app/DSL/components/elements/ImageUpload";
import {
  Flex,
  Button,
  Label,
  Checkbox,
  AspectImage,
} from "@theme-ui/components";
import { InputWrap } from "../../../../app/DSL/components/form/StyledInput";

export default function ProductImage({
  submitImage,
  close,
  create,
  submitText,
  product,
  loading,
}) {
  const [uploading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [file, setFile] = useState();
  const [createAnother, setCreateAnother] = useState(true);
  const cropper = React.createRef();
  const uploadFile = () => {
    setLoading(true);
    const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/upload`;
    const xhr = new XMLHttpRequest();
    const fd = new FormData();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

    xhr.onreadystatechange = function (e) {
      if (xhr.readyState === 4 && xhr.status === 200) {
        // File uploaded successfully

        const response = JSON.parse(xhr.responseText);
        // https://res.cloudinary.com/cloudName/image/upload/v1483481128/public_id.jpg

        const url = response.secure_url;
        submitImage(url, create && createAnother);
        setLoading(false);
        setFile(null);
        //Update firebase
      }
    };

    fd.append("upload_preset", process.env.REACT_APP_PRESET);
    fd.append("tags", "browser_upload"); // Optional - add tag for image admin in Cloudinary
    fd.append(
      "file",
      cropper?.current?.cropper
        ?.getCroppedCanvas({ width: 400, height: 400 })
        .toDataURL()
    );
    xhr.send(fd);
  };

  return (
    <div>
      {product?.productImageUrl && !update ? (
        <div style={{ margin: "1em" }}>
          <div style={{ textAlign: "center", maxWidth: "400px" }}>
            <AspectImage ratio={1 / 1} src={product.productImageUrl} />
          </div>
          <Button onClick={() => setUpdate(true)}>Update Image</Button>
        </div>
      ) : (
        <div style={{ margin: "1em" }}>
          <ImageUpload setFile={setFile} file={file} cropper={cropper} />
          {!create && (
            <Button onClick={() => setUpdate(false)}>
              Cancel Update Image
            </Button>
          )}
        </div>
      )}
      {create && (
        <InputWrap style={{ gridArea: "checkbox" }}>
          <Label variant="radioLabel">
            <Checkbox
              checked={createAnother}
              onChange={(e) => {
                setCreateAnother(!createAnother);
              }}
            />
            Create another product?
          </Label>
        </InputWrap>
      )}

      <Flex sx={{ justifyContent: "space-between" }}>
        <Button variant="outline" onClick={close}>
          Cancel
        </Button>
        {loading || uploading ? (
          <Button>Loading...</Button>
        ) : (
          <Button
            onClick={() => {
              if (update || create) {
                uploadFile();
              } else {
                submitImage(product.productImageUrl);
              }
            }}
          >
            {submitText}
          </Button>
        )}
      </Flex>
    </div>
  );
}
