/** @jsx jsx */
import { jsx } from "theme-ui";
import {
  Label,
  Input,
  Radio,
  Message,
  Textarea,
  Flex,
} from "@theme-ui/components";
import { InputWrap } from "../../app/DSL/components/form/StyledInput";

import Select from "react-select";
import Field from "../DSL/components/form/Field";
import DateInput from "../DSL/components/form/DateInput";

export default function Form({
  initialValues,
  error,
  formFields,
  values,
  inputProps,
}) {
  return (
    <div>
      {formFields
        .filter(({ hidden }) => !hidden)
        .map(
          (
            { title, name, placeholder, type, options, readOnly, isMulti },
            idx
          ) => {
            const fieldProps = { name, values, error, title, key: idx, type };
            switch (type) {
              case "date":
                return (
                  <Field {...fieldProps}>
                    <DateInput
                      inputProps={inputProps(name)}
                      startDate={values[name].value}
                    />
                  </Field>
                );

              case "radio":
                return (
                  <Field {...fieldProps}>
                    <Flex>
                      {options.map((o, idx) => (
                        <InputWrap key={idx}>
                          <Label
                            variant="radioLabel"
                            sx={{
                              textTransform: "capitalize",
                            }}
                          >
                            <Radio
                              {...inputProps(name)}
                              id={o}
                              value={o}
                              checked={values[name].value === o}
                              defaultChecked={
                                initialValues &&
                                initialValues[name] &&
                                o === initialValues[name]
                              }
                            />
                            {o}
                          </Label>
                        </InputWrap>
                      ))}
                    </Flex>
                  </Field>
                );

              case "select":
                return (
                  <Field {...fieldProps}>
                    <Select
                      styles={customStyles}
                      {...inputProps(name)}
                      options={options}
                      value={values[name].selectedOption}
                      placeholder={placeholder}
                    />
                  </Field>
                );

              case "multiselect":
                return (
                  <Field {...fieldProps}>
                    <Select
                      styles={customStyles}
                      {...inputProps(name)}
                      options={options}
                      value={values[name].selectedOptions}
                      placeholder={placeholder}
                      isMulti
                    />
                  </Field>
                );

              case "textarea":
                return (
                  <Field key={idx} {...fieldProps}>
                    <Textarea
                      {...inputProps(name)}
                      placeholder={placeholder}
                      rows={3}
                    />
                  </Field>
                );

              default:
                return (
                  <Field key={idx} {...fieldProps}>
                    <Input {...inputProps(name)} placeholder={placeholder} />
                  </Field>
                );
            }
          }
        )}

      {error && (
        <Message variant="warning" style={{ gridArea: "error" }}>
          {error.message}
        </Message>
      )}
    </div>
  );
}

export const customStyles = {
  control: (provided, state) => ({
    display: "flex",
    alignItems: "center",
    boxShadow: "inset 0 0.0625em 0.125em rgba(10,10,10,.05)",
    backgroundColor: "#fff",
    borderColor: state.isFocused
      ? "var(--theme-ui-colors-tusk-shade-900,#CDCDD6) !important"
      : "var(--theme-ui-colors-tusk-shade-500,#E2E2E7) !important",
    border: "1px solid",
    borderRadius: 4,
    color: "#363636",
    font: "inherit",
    outline: 0,
  }),
  input: (provided, state) => ({
    ...provided,
    paddingBottom: "calc(.5em - 2px)",
    paddingLeft: "calc(.75em - 2px)",
    paddingRight: "calc(.75em - 2px)",
    paddingTop: "calc(.5em - 2px)",
  }),
  container: (provided, state) => ({
    ...provided,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};
