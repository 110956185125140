import React, { useState, useEffect } from "react";
import CardModal from "../../../app/DSL/components/elements/CardModal";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import StatusLabel from "../../../app/DSL/components/elements/StatusLabel";
import { Label, Button, Flex } from "@theme-ui/components";
import { InputWrap } from "../../../app/DSL/components/form/StyledInput";
import Select from "react-select";
import { customStyles } from "../../../app/forms/Form";
import { useOrders } from "../../../app/context/orders";
import { useToasts } from "react-toast-notifications";

const statuses = [
  {
    label: "New",
    value: "new",
  },
  { label: "Processing", value: "processing" },
  { label: "Ready", value: "ready" },
  { label: "Completed", value: "completed" },
  { label: "Cancelled", value: "cancelled" },
];

export default function StatusModal({ open, close, order, orders }) {
  const { changeStatuses } = useOrders();
  const [status, setStatus] = useState(order?.status);
  useEffect(() => {
    if (status === undefined && order?.status) {
      setStatus(statuses.find((s) => s.value === order.status));
    }
  }, [order, status]);

  const { addToast } = useToasts();

  const submit = () =>
    changeStatuses({ orderIds: orders ? orders : [order.id], status });

  return (
    <CardModal isOpen={open} close={close} width="50%">
      <BlockHeaderContainer title="Update order status" />

      <InputWrap style={{ margin: "1em 0 2em" }}>
        {orders ? (
          <div>
            <Label>
              Update {orders?.length} order{orders?.length === 1 ? "" : "s"}{" "}
            </Label>
          </div>
        ) : (
          <div>
            <Label>Current status :</Label>
            {order?.status && <StatusLabel status={order?.status} />}
          </div>
        )}
      </InputWrap>
      <InputWrap style={{ margin: "1em 0 2em" }}>
        <Label>New status</Label>
        <div className="modal-select">
          <Select
            styles={customStyles}
            isClearable
            isSearchable
            options={statuses}
            value={status}
            onChange={(option) => setStatus(option)}
          />
        </div>
      </InputWrap>
      <Flex style={{ justifyContent: "space-between" }}>
        <Button variant="outline" onClick={() => close()}>
          Cancel
        </Button>
        <Button
          //TODO:move to function
          onClick={() =>
            submit().then(() => {
              addToast(
                orders
                  ? `${orders.length} order${
                      orders?.length === 1 ? "" : "s"
                    } statuses changed to ${status.value}`
                  : `Order #${order.id
                      .slice(0, 6)
                      .toUpperCase()} status changed to ${status.value}`,
                {
                  appearance: "success",
                  autoDismiss: true,
                  autoDismissTimeout: 3000,
                }
              );
              close();
            })
          }
        >
          Update status
        </Button>
      </Flex>
    </CardModal>
  );
}
