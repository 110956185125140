export const formatProduct = (product) => {
  return {
    productLink: `${product.id}__${product.name}`,
    productImageUrl: product.productImageUrl ? product.productImageUrl : null,
    quantity: product.quantity,
    size: product.size,
    dateAdded: new Date(),
  };
};

export const formatWishlistProduct = (product) => {
  return {
    productLink: `${product.id}__${product.name}`,
    productImageUrl: product.productImageUrl ? product.productImageUrl : null,
    dateAdded: new Date(),
  };
};
