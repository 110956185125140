import React from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Card } from "@theme-ui/components";
import { useCarts } from "../../../app/context/carts";
import { useOrders } from "../../../app/context/orders";
import { useStats } from "../../../app/context/stats";

export default function DashStats() {
    const { wishlistsTotal, cartsTotal } = useCarts();
    const { outstandingOrdersTotal } = useOrders();
    const { stats } = useStats();
    return (
        <Stats>
            <NavLink to="/admin-area/products">
                <Card variant="small" style={{ height: "100%" }}>
                    <h1>{wishlistsTotal}</h1>
                    <h3>Total points value of wishlisted items</h3>
                </Card>
            </NavLink>
            <NavLink to="/admin-area/products">
                <Card variant="small" style={{ height: "100%" }}>
                    <h1>{cartsTotal}</h1>
                    <h3>Total points value of items in carts</h3>
                </Card>
            </NavLink>
            <NavLink to="/admin-area/orders">
                <Card variant="small" style={{ height: "100%" }}>
                    <h1>{outstandingOrdersTotal}</h1>
                    <h3>Total points value of outstanding orders</h3>
                </Card>
            </NavLink>

            <Card variant="small" style={{ height: "100%" }}>
                <h1>{stats.todaysLogins ? stats.todaysLogins.length : 0}</h1>
                <h3>Unique shop logins today</h3>
            </Card>
        </Stats>
    );
}

const Stats = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1em;
    margin-bottom: 1em;
    text-align: center;
    row-gap: 1em;
    @media (max-width: 1025px) {
        grid-template-columns: 1fr 1fr;
    }

    a {
        transition: transform 0.3s ease;
        :hover {
            transform: translateY(-5px);
        }
    }
`;
