import React, { useMemo, useState } from "react";
import {
  ActionsTableContainer,
  StyledLabel,
  RoundButton,
} from "../../../app/DSL/components/elements/styled";
import Table from "../../../app/DSL/components/elements/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { useOrders } from "../../../app/context/orders";
import { format, isBefore } from "date-fns";
import StatusLabel from "../../../app/DSL/components/elements/StatusLabel";
import StatusModal from "./StatusModal";
import ViewModal from "./ViewModal";
import { Checkbox, Label } from "@theme-ui/components";
import { getObjFromLink } from "../../../app/context/helpers";

export default function OrdersTable({ selectedOrders, toggleSelected }) {
  const { orders } = useOrders();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalItem, setModalItem] = useState();
  const [modalType, setModalType] = useState();

  //TODO: move columns to separate file

  const columns = useMemo(
    () => [
      {
        Header: "",
        id: "check",
        accessor: (o) => o,
        Cell: (cell) => {
          return (
            cell.row.original.status !== "cancelled" && (
              <Label>
                <Checkbox
                  checked={selectedOrders.includes(cell.row.original.id)}
                  onChange={(e) => {
                    toggleSelected(cell.row.original.id);
                  }}
                />
              </Label>
            )
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "Order Number",
        id: "num",
        accessor: (o) => o.id,
        Cell: (cell) => (
          <h4>#{cell.row.original.id.slice(0, 6).toUpperCase()}</h4>
        ),
        filter: "fuzzyText",
      },
      {
        Header: "Placed by",
        id: "user",
        accessor: (o) => getObjFromLink(o.userLink).name,
        filter: "fuzzyText",
      },
      {
        Header: "Date Created",
        id: "created",
        accessor: (o) => o.dateCreated,
        Cell: (cell) => (
          <p>
            {cell.row.original?.dateCreated
              ? format(cell.row.original?.dateCreated.toDate(), "dd MMMM yyyy")
              : "-"}
          </p>
        ),
        filter: "fuzzyText",
      },
      {
        Header: "Estimated Delivery Date",
        id: "delivery",
        accessor: (o) => o.deliveryDate,
        Cell: (cell) => (
          <p>
            {cell.row.original?.deliveryDate
              ? format(cell.row.original?.deliveryDate.toDate(), "dd MMMM yyyy")
              : "-"}
          </p>
        ),
        filter: "fuzzyText",
      },
      {
        Header: "Total value",
        id: "price",
        accessor: (o) => `${o.totalPrice} points`,
        filter: "fuzzyText",
      },
      {
        Header: "Number of items",
        id: "numItems",
        accessor: (o) => o.items.length,
        Cell: (cell) => (
          <StyledLabel colour="teal">
            {cell.row.original.items.length}
          </StyledLabel>
        ),
        filter: "fuzzyText",
      },
      {
        Header: "Status",
        id: "status",
        accessor: (o) => o.status,
        Cell: (cell) => <StatusLabel status={cell.row.original.status} />,
        filter: "fuzzyText",
      },
      {
        Header: "Actions",
        accessor: (u) => u.id,
        Cell: (cell) => {
          return (
            <span>
              <RoundButton
                data-tip="View Order"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  setModalOpen(true);
                  setModalItem(cell.row.original);
                  setModalType("view");
                }}
              >
                <FontAwesomeIcon icon={["fas", "eye"]} />
              </RoundButton>
              {cell.row.original.status !== "cancelled" && (
                <RoundButton
                  data-tip="Edit Order Status"
                  data-for="actionName"
                  variant="secondary"
                  onClick={() => {
                    setModalOpen(true);
                    setModalItem(cell.row.original);
                    setModalType("status");
                  }}
                >
                  <FontAwesomeIcon icon={["fas", "pencil"]} />
                </RoundButton>
              )}
              <ReactTooltip id="actionName" effect="solid" />
            </span>
          );
        },
        className: "actions",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [selectedOrders, toggleSelected]
  );

  const mobileColumns = useMemo(
    () => [
      {
        Header: "",
        id: "check",
        accessor: (o) => o,
        Cell: (cell) => {
          return (
            cell.row.original.status !== "cancelled" && (
              <Label>
                <Checkbox
                  checked={selectedOrders.includes(cell.row.original.id)}
                  onChange={(e) => {
                    toggleSelected(cell.row.original.id);
                  }}
                />
              </Label>
            )
          );
        },
        disableFilters: true,
        disableSortBy: true,
      },

      {
        Header: "#",
        id: "num",
        accessor: (o) => o.id,
        Cell: (cell) => (
          <h4>#{cell.row.original.id.slice(0, 6).toUpperCase()}</h4>
        ),
        filter: "fuzzyText",
      },

      {
        Header: "Date Created - Status",
        id: "status",
        accessor: (o) => `${o.dateCreated} ${o.status}`,
        Cell: (cell) => (
          <>
            <p>
              {cell.row.original?.dateCreated
                ? format(
                    cell.row.original?.dateCreated.toDate(),
                    "dd MMMM yyyy"
                  )
                : "-"}
            </p>
            <StatusLabel status={cell.row.original?.status} />
          </>
        ),
        filter: "fuzzyText",
      },
      {
        Header: "Actions",
        accessor: (u) => u.id,
        Cell: (cell) => {
          return (
            <span>
              <RoundButton
                data-tip="View Order"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  setModalOpen(true);
                  setModalItem(cell.row.original);
                  setModalType("view");
                }}
              >
                <FontAwesomeIcon icon={["fas", "eye"]} />
              </RoundButton>
              {cell.row.original.status !== "cancelled" && (
                <RoundButton
                  data-tip="Edit Order Status"
                  data-for="actionName"
                  variant="secondary"
                  onClick={() => {
                    setModalOpen(true);
                    setModalItem(cell.row.original);
                    setModalType("status");
                  }}
                >
                  <FontAwesomeIcon icon={["fas", "pencil"]} />
                </RoundButton>
              )}

              <ReactTooltip id="actionName" effect="solid" />
            </span>
          );
        },
        className: "actions",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [selectedOrders, toggleSelected]
  );

  return (
    <div>
      <div className="not-mobile">
        <ActionsTableContainer numActions={2}>
          <Table
            selectedItems={selectedOrders}
            toggleSelected={toggleSelected}
            emptyMsg="No orders"
            columns={columns}
            data={
              orders
                ? orders.sort((a, b) =>
                    isBefore(a?.dateCreated?.toDate(), b?.dateCreated?.toDate())
                      ? 1
                      : -1
                  )
                : []
            }
            defaultRows={5}
            loading={orders === undefined}
          />
        </ActionsTableContainer>
      </div>
      <div className="mobile">
        <ActionsTableContainer numActions={1}>
          <Table
            selectedItems={selectedOrders}
            toggleSelected={toggleSelected}
            emptyMsg="No orders"
            columns={mobileColumns}
            data={
              orders
                ? orders.sort((a, b) =>
                    isBefore(a?.dateCreated?.toDate(), b?.dateCreated?.toDate())
                      ? 1
                      : -1
                  )
                : []
            }
            defaultRows={5}
            loading={orders === undefined}
          />
        </ActionsTableContainer>
      </div>

      <StatusModal
        open={modalOpen && modalType === "status"}
        close={() => setModalOpen(false)}
        order={modalItem}
      />
      <ViewModal
        open={modalOpen && modalType === "view"}
        close={() => setModalOpen(false)}
        order={modalItem}
        setModalType={setModalType}
      />
    </div>
  );
}
