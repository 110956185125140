import React, { useState, useEffect, useMemo } from "react";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import { Card, Image, Button } from "@theme-ui/components";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import styled from "@emotion/styled";
import { useOrders } from "../../app/context/orders";
import OrderCard from "./OrderCard";
import OrderBreakdown from "./OrderBreakdown";
import { NavLink } from "react-router-dom";
import { useUserContext } from "../../app/context/auth";
import { Ring } from "react-awesome-spinners";
import colours from "../../app/DSL/styles/colours";
import { primary } from "../../app/DSL/theme";
import { isBefore } from "date-fns";

export default function OrderHistory() {
  const { orders } = useOrders();
  const [order, setOrder] = useState();
  const user = useUserContext();
  const orderList = useMemo(
    () =>
      orders
        ?.filter((o) => o.userLink === `${user.id}__${user.displayName}`)
        .sort((a, b) =>
          isBefore(a?.dateCreated?.toDate(), b?.dateCreated?.toDate()) ? 1 : -1
        ),
    [user, orders]
  );
  useEffect(() => {
    if (order === undefined && orderList?.length > 0) setOrder(orderList[0]);
  }, [orderList, order]);
  return (
    <div>
      <PageHeaderContainer>Order History</PageHeaderContainer>
      {orders ? (
        orders.length > 0 ? (
          <Grid>
            <Card>
              <BlockHeaderContainer title="Order History" />

              {orderList.map((o) => (
                <OrderCard
                  order={o}
                  setOrder={setOrder}
                  active={o.id === order?.id}
                />
              ))}
            </Card>
            <Card className="not-mobile">
              {order && <OrderBreakdown order={order} />}
            </Card>
          </Grid>
        ) : (
          <Card>
            <EmptyState>
              <Image src="/assets/empty-box.svg" />
              <h3 style={{ margin: 0 }}>You have not placed any orders yet</h3>
              {/* TODO: remove Reconise hard coded */}
              <p>Visit the shop now to place your Reconise order today</p>
              <NavLink to="/shop">
                <Button>Shop now</Button>
              </NavLink>
            </EmptyState>
          </Card>
        )
      ) : (
        <div style={{ textAlign: "center" }}>
          <Ring color={colours[primary].shade["500"]} />
        </div>
      )}
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 5fr;
  column-gap: 1em;
  align-items: start;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 1em;

    .mobile {
      display: block !important;
    }
    .not-mobile {
      display: none !important;
    }
    @media (max-width: 576px) {
      > div:first-of-type {
        padding: 1em;
      }
    }
  }
`;

const EmptyState = styled.div`
  width: 400px;
  margin: auto;

  text-align: center;
`;
