import styled from "@emotion/styled";
import { Button, Heading } from "@theme-ui/components";
import { primary } from "../../theme";
//TODO: add comments and sections

export const RoundButton = styled(Button)`
  border-radius: 50% !important;
  text-align: center;
  padding: 0;
  width: 40px;
  height: 40px;
`;

export const InputPlaceholder = styled.div`
  line-height: 1.214286em;
  color: transparent;
  padding: 0.67857143em 0;
  margin: 0.5em 0;
  min-width: 100px;
`;

export const TableContainer = styled.div`
  padding: 1rem;

  table {
    width: 100%;
    border-spacing: 0;
    th,
    td {
      border-bottom: 1px solid rgba(74, 74, 74, 0.1);
      text-align: left;
      margin: 0;
      padding: 0.5rem;
    }
    th span {
      svg {
        margin: auto 10px;
      }
    }
    input:not(.rc-input-number-input) {
      margin: 0.5em 0;
      font-weight: normal;
    }
    @media (max-width: 1366px) {
      th,
      td {
        max-width: 225px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .centered {
  }

  .pagination {
    margin: 2em auto 0 auto;
    display: grid;
    grid-template-columns: 40px 40px 1fr 40px 40px;
    align-items: center;
    grid-column-gap: 0.5em;
    .controls {
      display: grid;
      grid-template-columns: 100px 5px 100px 100px;
      grid-row-gap: 0.5em;
      margin: 0 1em;
      justify-self: center;
      justify-items: center;
      align-items: center;
      input {
        margin-right: 0;
      }
      div {
        width: 100%;
        grid-column: 1 / 5;
      }
      span {
        margin: 0 0.5em;
      }
    }
  }

  @media (max-width: 1024px) {
    .mobile {
      text-align: center;
      display: grid;
      grid-template-columns: 1fr;
      p {
        margin: 0.5em;
      }
    }
  }
`;

export const EmptyTableState = styled.td`
  text-align: center !important;
  color: #c4c4c4;
  font-weight: 700;
  padding: 2em !important;
  h3.ui.header {
    color: #c4c4c4;
    margin: 0.5em;
  }
  p {
    margin-top: 0.5em;
  }
`;

export const EmptyState = styled.div`
  text-align: center !important;
  color: #c4c4c4;
  font-weight: 700;
  h3.ui.header {
    color: #c4c4c4;
    margin: 0.5em;
  }
  p {
    margin-top: 0.5em;
  }
`;

export const ActionsTableContainer = styled(TableContainer)`
  table {
    td,
    th {
      :first-of-type {
        ${(props) => (props.selectable ? "max-width: 20px !important;" : "")}
        label {
          ${(props) => (props.selectable ? "margin: 0" : "")}
        }
      }
      /* changing width of last column for actions */
      :last-child {
        max-width: calc(
          ${(props) =>
            `40px * ${props.numActions} + 10px * (${props.numActions} - 1)`}
        );
        ${(props) =>
          props.condensed
            ? "max-width: 60px !important; text-align:right"
            : `span {display: grid; grid-template-columns: repeat(${
                props.numActions ? props.numActions : 2
              }, 40px); column-gap: 10px; row-gap: 10px;}`};
      }
    }
  }
`;

export const UsersTableContainer = styled(ActionsTableContainer)`
  table {
    td,
    th {
      /* changing width of first column for icons */
      :first-of-type {
        text-align: center;
        max-width: 20px !important;
      }
    }
  }
`;

export const ImageBlock = styled.div`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  margin: auto;

  padding: ${(props) => (props.border ? props.border : "8px")};
  border-radius: 50%;
  position: relative;
  background: linear-gradient(
    ${(props) => props.theme.colors[primary].shade["100"]},
    ${(props) => props.theme.colors[primary].shade["500"]}
  );
  .img {
    border-radius: 50%;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    background-image: url(${(props) => props.url});
    background-position: center;
    background-size: cover;
  }
  .hover {
    opacity: 0;
  }

  .hover {
    position: absolute;
    border-radius: 50%;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    background: rgba(255, 255, 255, 0.5);
    top: ${(props) => (props.border ? props.border : "8px")};
    display: grid;
    justify-items: center;
    align-items: center;
    row-gap: 1em;
    &:hover {
      opacity: 1;
    }
  }
`;

export const Likes = styled(Heading)`
  color: ${(props) =>
    props.active ? props.theme.colors[primary].shade["500"] : "black"};
  cursor: ${(props) => (props.noClick ? "inherit" : "pointer")};
`;

export const StyledLabel = styled.div`
  padding: 0.3em 1em;
  border-radius: 20px;
  text-align: center;
  font-weight: bold;
  background: ${(props) =>
    props.theme.colors[props.colour].shade[props.code ? props.code : "600"]};
  color: ${(props) =>
    props.textColour
      ? props.textColour
      : props.theme.colors[props.colour].font[props.code ? props.code : "600"]};
`;

export const SeeAllLink = styled.div`
  position: absolute;
  bottom: 2em;
  width: calc(100% - 4em);
  a {
    display: block;
    width: 100%;
    text-align: center;
  }
`;
