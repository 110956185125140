import React, { useState, useCallback } from "react";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import { Card } from "@theme-ui/components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import ProductsTable from "./ProductsTable";
import ConfirmationModal from "../../../app/DSL/components/elements/ConfirmationModal";
import { useProducts } from "../../../app/context/products";
import { useToasts } from "react-toast-notifications";
import { FloatingButton } from "../../../app/DSL/components/elements/FloatingButton";
import CreateProductModal from "./CreateProductModal";

//TODO: move to separate page instead of modal for create and edit

export default function ManageProducts() {
  const { archiveProducts, featureProducts } = useProducts();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const { addToast } = useToasts();
  const toggleSelected = useCallback(
    (id) => {
      console.log(id);
      if (selectedProducts.includes(id)) {
        setSelectedProducts((prev) => prev.filter((p) => p !== id));
      } else {
        setSelectedProducts((prev) => [...prev, id]);
      }
    },
    [selectedProducts]
  );
  const archive = () => {
    return archiveProducts(selectedProducts).then(() => {
      addToast(`Products Archived`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      setSelectedProducts([]);
      setModalOpen(false);
    });
  };

  const feature = () => {
    return featureProducts(selectedProducts).then(() => {
      addToast(`Products Featured`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      setSelectedProducts([]);
      setModalOpen(false);
    });
  };
  return (
    <div>
      <PageHeaderContainer>Manage Products</PageHeaderContainer>
      <Card>
        <BlockHeaderContainer
          title={"View Products"}
          buttonText="New Product"
          actionType="button"
          action={() => {
            setModalOpen(true);
            setModalType("create");
          }}
        />
        <div /* className="not-mobile" */>
          <ProductsTable
            selectedProducts={selectedProducts}
            toggleSelected={toggleSelected}
          />
        </div>
      </Card>
      <FloatingButton
        onClick={() => {
          setModalOpen(true);
          setModalType("archive");
        }}
        show={selectedProducts.length > 0}
      >
        Archive {selectedProducts.length} product
        {selectedProducts.length === 1 ? "" : "s"}
      </FloatingButton>
      <FloatingButton
        onClick={() => {
          setModalOpen(true);
          setModalType("feature");
        }}
        show={selectedProducts.length > 0}
        style={{ marginLeft: "200px" }}
      >
        Feature {selectedProducts.length} product
        {selectedProducts.length === 1 ? "" : "s"}
      </FloatingButton>
      <ConfirmationModal
        setOpen={setModalOpen}
        isOpen={
          modalOpen && (modalType === "archive" || modalType === "feature")
        }
        actionType={modalType}
        action={modalType === "archive" ? archive : feature}
        item={{}}
        name={`${selectedProducts.length} product${
          selectedProducts.length === 1 ? "" : "s"
        }`}
      />
      <CreateProductModal
        open={modalOpen && modalType === "create"}
        close={() => setModalOpen(false)}
      />
    </div>
  );
}
