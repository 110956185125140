import React, { useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTooltip from "react-tooltip";
import { format, isBefore } from "date-fns";
import { useOrders } from "../../../app/context/orders";
import {
  StyledLabel,
  RoundButton,
  ActionsTableContainer,
} from "../../../app/DSL/components/elements/styled";
import StatusLabel from "../../../app/DSL/components/elements/StatusLabel";
import Table from "../../../app/DSL/components/elements/Table";
import ViewModal from "../ManageOrders/ViewModal";
import StatusModal from "../ManageOrders/StatusModal";

export default function OrdersTable() {
  const { orders } = useOrders();
  const ordersList = useMemo(
    () =>
      orders
        ? orders
            .filter((o) => o.status === "new" || o.status === "processing")
            .sort((a, b) =>
              isBefore(a?.dateCreated?.toDate(), b?.dateCreated?.toDate())
                ? 1
                : -1
            )
        : [],
    [orders]
  );

  const [modalOpen, setModalOpen] = useState(false);
  const [modalItem, setModalItem] = useState();
  const [modalType, setModalType] = useState();

  const columns = useMemo(
    () => [
      {
        Header: "#",
        id: "num",
        accessor: (o) => o.id,
        Cell: (cell) => (
          <h4>#{cell.row.original.id.slice(0, 6).toUpperCase()}</h4>
        ),
        filter: "fuzzyText",
      },
      {
        Header: "Date Created",
        id: "created",
        accessor: (o) => o.dateCreated,
        Cell: (cell) => (
          <p>
            {cell.row.original?.dateCreated
              ? format(cell.row.original?.dateCreated.toDate(), "dd MMMM yyyy")
              : "-"}
          </p>
        ),
        filter: "fuzzyText",
      },

      {
        Header: "Number of items",
        id: "numItems",
        accessor: (o) =>
          o.items.reduce((tot, i) => {
            return (tot += i.quantity);
          }, 0),
        Cell: (cell) => (
          <StyledLabel colour="teal">
            {cell.row.original.items.reduce((tot, i) => (tot += i.quantity), 0)}
          </StyledLabel>
        ),
        filter: "fuzzyText",
      },
      {
        Header: "Status",
        id: "status",
        accessor: (o) => o.status,
        Cell: (cell) => <StatusLabel status={cell.row.original.status} />,
        filter: "fuzzyText",
      },
      {
        Header: "Actions",
        accessor: (u) => u.id,
        Cell: (cell) => {
          return (
            <span>
              <RoundButton
                data-tip="View Order"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  setModalOpen(true);
                  setModalItem(cell.row.original);
                  setModalType("view");
                }}
              >
                <FontAwesomeIcon icon={["fas", "eye"]} />
              </RoundButton>
              <RoundButton
                data-tip="Edit Order Status"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  setModalOpen(true);
                  setModalItem(cell.row.original);
                  setModalType("status");
                }}
              >
                <FontAwesomeIcon icon={["fas", "pencil"]} />
              </RoundButton>

              <ReactTooltip id="actionName" effect="solid" />
            </span>
          );
        },
        className: "actions",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  return (
    <div style={{ marginBottom: "3em" }}>
      <ActionsTableContainer>
        <Table
          emptyMsg="No orders"
          columns={columns}
          data={ordersList}
          defaultRows={5}
          loading={orders === undefined}
        />
      </ActionsTableContainer>

      <StatusModal
        open={modalOpen && modalType === "status"}
        close={() => setModalOpen(false)}
        order={modalItem}
      />
      <ViewModal
        open={modalOpen && modalType === "view"}
        close={() => setModalOpen(false)}
        order={modalItem}
        setModalType={setModalType}
      />
    </div>
  );
}
