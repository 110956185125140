import styled from "@emotion/styled";

export const PageWrapper = styled.div`
  display: grid;
  grid-template-columns: 75px 1fr;
  @media (max-width: 800px) {
    .sidenav {
      display: none;
    }
    grid-template-columns: 1fr;
  }
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  padding: 0 5em 2em;
  background-image: url("/swoosh.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: ${(props) => props.theme.colors.tusk.shade["400"]};
  display: grid;
  grid-template-rows: 1fr auto;
  @media (max-width: 768px) {
    padding: 0 2em;
    overflow-x: hidden;
  }
  @media (max-width: 576px) {
    padding: 0 1em;
    overflow-x: hidden;
  }
`;
