import React from "react";
import { Card, Divider, AspectImage } from "@theme-ui/components";
import styled from "@emotion/styled";
import { getObjFromLink } from "../../app/context/helpers";

export default function MobileBreakdownCard({ item }) {
  return (
    <ProdCard>
      <AspectImage
        ratio={1 / 1}
        src={
          item.productImageUrl
            ? item.productImageUrl
            : "/assets/product-placeholder.gif"
        }
      />
      <div className="description">
        <Divider />
        <h3>
          {getObjFromLink(item.productLink).name}
          {item.size !== "fixed" ? `  (${item.size})` : ""}
        </h3>
        <p>
          <strong>Price:</strong> {item.price} Points
        </p>
        <p>
          <strong>Quantity:</strong> {item.quantity}
        </p>

        <p>
          <strong>Total:</strong> {item.price * item.quantity} Points
        </p>
      </div>
      <div></div>
    </ProdCard>
  );
}

const ProdCard = styled(Card)`
  display: grid;
  grid-template-rows: auto auto 60px;
  .divider {
    margin-bottom: 1em;
  }

  @media (max-width: 768px) {
    grid-template-rows: auto auto auto;
  }
`;

// const ButtonGrid = styled.div`
//   display: grid;
//   grid-template-columns: 1fr auto;
//   column-gap: 1em;

//   @media (max-width: 768px) {
//     grid-template-columns: 1fr;
//     row-gap: 1em;
//   }
// `;
