import React from "react";
import styled from "@emotion/styled";
import { primary } from "../../theme";

export default function StatusLabel({ status, active }) {
  return (
    <Label status={status} active={active}>
      {status.toUpperCase()}
    </Label>
  );
}

const Label = styled.div`
  padding: 0.3em 1em;
  border-radius: 20px;
  text-align: center;
  font-weight: bold;
  background: ${(props) => {
    if (props.active) {
      return "#ffffff90";
    } else {
      switch (props.status) {
        case "new":
          return props.theme.colors.blue.half["500"];
        case "processing":
          return props.theme.colors.lightTeal.half["500"];
        case "ready":
          return props.theme.colors.green.half["500"];
        case "completed":
          return props.theme.colors.lightGreen.half["500"];
        case "cancelled":
          return props.theme.colors.orange.half["500"];
        default:
          return props.theme.colors.tusk.half["500"];
      }
    }
  }};
  color: ${(props) => {
    if (props.active) {
      return props.theme.colors[primary].shade["500"];
    } else {
      switch (props.status) {
        case "new":
          return props.theme.colors.blue.shade["900"];
        case "processing":
          return props.theme.colors.lightTeal.shade["900"];
        case "ready":
          return props.theme.colors.green.shade["900"];
        case "completed":
          return props.theme.colors.lightGreen.shade["900"];
        case "cancelled":
          return props.theme.colors.orange.shade["900"];
        default:
          return props.theme.colors.tusk.shade["900"];
      }
    }
  }};
  @media (max-width: 576px) {
    font-size: 0.7em;
  }
`;
