import React, { useState, useEffect } from "react";
import Form from "../../../../app/forms/Form";
import { useForm } from "../../../../app/forms/useForm";
import { InputWrap } from "../../../../app/DSL/components/form/StyledInput";
import {
  Label,
  Checkbox,
  Flex,
  Button,
  Input,
  Card,
} from "@theme-ui/components";
import InputNum from "rc-input-number";
import "rc-input-number/assets/index.css";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ProductForm({
  submit,
  loading,
  error,
  submitText,
  cancel,
  create,
  initialValues,
}) {
  const [items, setItems] = useState();

  const [newItem, setNewItem] = useState({});
  const [featured, setFeatured] = useState();

  useEffect(() => {
    if (featured === undefined && initialValues?.isFeatured !== undefined) {
      setFeatured(initialValues.isFeatured);
    } else if (featured === undefined) {
      setFeatured(false);
    }
  }, [featured, initialValues]);

  const updatePrice = (price, size) => {
    if (items) {
      const filteredItems = items.filter((i) => i.size !== size);
      const item = items.find((i) => i.size === size);
      setItems([...filteredItems, { ...item, price: price }]);
    } else {
      setItems([{ size, price: price, inStock: true }]);
    }
  };

  const updateSize = (e, size) => {
    if (items) {
      const filteredItems = items.filter((i) => i.size !== size);
      const item = items.find((i) => i.size === size);
      setItems([...filteredItems, { ...item, size: e.target.value }]);
    } else {
      setItems([{ size: e.target.value, price: 0, inStock: true }]);
    }
  };

  const addSize = () => {
    setItems([
      ...items,
      { size: newItem.size, price: newItem.price, inStock: true },
    ]);
    setNewItem({ size: "", price: 0 });
  };

  const setInStock = (e, size) => {
    const filteredItems = items.filter((i) => i.size !== size);
    const item = items.find((i) => i.size === size);
    if (item) {
      setItems([...filteredItems, { ...item, inStock: !item.inStock }]);
    } else {
      setItems([{ size, price: 0, inStock: false }]);
    }
  };

  const formFields = [
    {
      name: "name",
      title: "Product Name",
      type: "text",
      validate: (x) => x.length >= 3,
      error: "Name must be longer than 3 characters",
      placeholder: "Product Name",
      readOnly: initialValues?.name !== undefined,
    },
    {
      name: "description",
      title: "Product Description",
      type: "textarea",
      validate: (x) => x.length >= 3,
      error: "Name must be longer than 3 characters",
      placeholder: "Product Description",
    },
    {
      name: "stockType",
      title: "Stock Type",
      type: "radio",
      options: ["fixed", "variable"],
      validate: (x) => x.length > 1,
      error: "Please select a type",
    },
  ];

  const { formValid, inputProps, formattedValues, values, reset } = useForm(
    formFields,
    create ? { stockType: "fixed" } : initialValues
  );

  useEffect(() => {
    if (!items && initialValues?.items) {
      setItems(initialValues.items);
    } else {
      if (
        formattedValues.stockType === "fixed" &&
        (items?.length > 1 || !items)
      ) {
        setItems([{ size: "fixed", price: 0, inStock: true }]);
      }

      if (
        formattedValues.stockType === "variable" &&
        items?.find((i) => i.size === "fixed")
      ) {
        setItems([]);
      }
    }
  }, [formattedValues, items, initialValues]);
  //TODO:clean up
  return (
    <form
      onSubmit={(e) =>
        submit(e, { ...formattedValues, items, isFeatured: featured }, reset)
      }
    >
      <Form
        type="product"
        {...{
          values,
          inputProps,
          formValid,
          loading,
          error,
          submitText,
          formFields,
          cancel,
        }}
      />

      {formattedValues.stockType === "fixed" ? (
        <InputGrid style={{ gridTemplateColumns: "1fr auto" }}>
          <InputWrap style={{ marginBottom: "1em" }}>
            <Label>Price</Label>
            <InputNum
              min={0}
              step={10}
              value={items && items.length > 0 ? items[0].price : 0}
              style={{ width: "100%", textAlign: "left" }}
              onChange={(num) => {
                updatePrice(num, "fixed");
              }}
            />
          </InputWrap>
          <InputWrap>
            <Label variant="radioLabel">
              <Checkbox
                checked={items && items.length > 0 ? items[0].inStock : true}
                onChange={(e) => {
                  setInStock(e, "fixed");
                }}
              />
              In Stock
            </Label>
          </InputWrap>
        </InputGrid>
      ) : (
        <Card style={{ marginBottom: "1em", padding: "1em" }}>
          <h4>Create a new size</h4>
          <InputGrid>
            <InputWrap>
              <Label>Size</Label>
              <Input
                value={newItem.size}
                onChange={(e) => {
                  e.persist();
                  setNewItem((prev) => ({
                    ...prev,
                    size: e.target.value,
                  }));
                }}
              />
            </InputWrap>
            <InputWrap>
              <Label>Price</Label>
              <InputNum
                min={0}
                step={10}
                value={newItem.price}
                style={{ width: "100%", textAlign: "left" }}
                onChange={(num) => {
                  setNewItem((prev) => ({
                    ...prev,
                    price: num,
                  }));
                }}
              />
            </InputWrap>
            <Button onClick={() => addSize()}>
              <FontAwesomeIcon icon={["far", "plus"]} />
            </Button>
          </InputGrid>
          {items?.length > 0 && <h4>Sizes</h4>}
          {items?.map((i, idx) => {
            return (
              <InputGrid key={idx}>
                <InputWrap>
                  <Label>Price</Label>
                  <Input
                    value={i.size}
                    onChange={(e) => updateSize(e, i.size)}
                  />
                </InputWrap>
                <InputWrap>
                  <Label>Price</Label>
                  <InputNum
                    min={0}
                    step={10}
                    value={i.price}
                    style={{
                      width: "100%",
                      textAlign: "left",
                    }}
                    onChange={(num) => {
                      updatePrice(num, i.size);
                    }}
                  />
                </InputWrap>
                <InputWrap>
                  <Label variant="radioLabel">
                    <Checkbox
                      checked={i.inStock}
                      onChange={(e) => {
                        setInStock(e, i.size);
                      }}
                    />
                    In Stock
                  </Label>
                </InputWrap>
              </InputGrid>
            );
          })}
        </Card>
      )}
      <InputWrap style={{ gridArea: "checkbox" }}>
        <Label variant="radioLabel">
          <Checkbox
            checked={featured}
            onChange={(e) => {
              setFeatured(!featured);
            }}
          />
          Set as featured product
        </Label>
      </InputWrap>

      <InputWrap style={{ gridArea: "button" }}>
        {cancel ? (
          <Flex sx={{ justifyContent: "space-between" }}>
            <Button variant="outline" onClick={cancel}>
              Cancel
            </Button>
            {loading ? (
              <Button disabled={loading}>Loading</Button>
            ) : (
              <Button
                type="submit"
                variant={
                  !formValid && items?.length > 0 ? "disabled" : "primary"
                }
              >
                {submitText}
              </Button>
            )}
          </Flex>
        ) : loading ? (
          <Button disabled={loading}>Loading</Button>
        ) : (
          <Button
            type="submit"
            variant={!formValid && items?.length > 0 ? "disabled" : "primary"}
          >
            {submitText}
          </Button>
        )}
      </InputWrap>
    </form>
  );
}

const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  column-gap: 1em;
  align-items: end;
  button,
  > div > label {
    margin-bottom: 0.5em;
  }
`;
