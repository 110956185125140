import React, { createContext, useState, useContext, useEffect } from "react";
import { MenuProvider } from "../menu";
import { ProductsProvider } from "../products";
import { CartProvider } from "../cart";
import { OrdersProvider } from "../orders";
import { SlidersProvider } from "../sliders";
import { CartsProvider } from "../carts";
import { StatsProvider } from "../stats";

export const DataProvider = ({ children }) => {
    return (
        <ProductsProvider>
            <OrdersProvider>
                <CartProvider>
                    <CartsProvider>
                        <MenuProvider>
                            <SlidersProvider>
                                <StatsProvider>{children}</StatsProvider>
                            </SlidersProvider>
                        </MenuProvider>
                    </CartsProvider>
                </CartProvider>
            </OrdersProvider>
        </ProductsProvider>
    );
};

const LoadingContext = createContext({});

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadingArr = [];
        console.log(loadingArr);
        setLoading(
            !loadingArr.every((l) => {
                return l === false;
            })
        );
    }, []);

    return (
        <LoadingContext.Provider value={{ loading }}>
            {children}
        </LoadingContext.Provider>
    );
};

export const LoadingConsumer = LoadingContext.Consumer;

export const useLoading = () => useContext(LoadingContext);
