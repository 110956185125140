import React from "react";
import styled from "@emotion/styled";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import { Card, Button, Image } from "@theme-ui/components";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import { useCart } from "../../app/context/cart";
import WishlistTable from "./WishlistTable";
import { NavLink } from "react-router-dom";
import colours from "../../app/DSL/styles/colours";
import { primary } from "../../app/DSL/theme";
import { Ring } from "react-awesome-spinners";
export default function Wishlist() {
  const { wishlist } = useCart();

  return (
    <div>
      <PageHeaderContainer>Wishlist</PageHeaderContainer>
      <Card>
        <BlockHeaderContainer
          title={`Your wishlist contains ${
            wishlist?.items.length ? wishlist?.items.length : 0
          } item${wishlist?.items.length === 1 ? "" : "s"}`}
          buttonText="View Cart"
          actionType="link"
          link="/cart"
        />
        {wishlist ? (
          wishlist?.items.length > 0 ? (
            <WishlistTable />
          ) : (
            <EmptyState>
              <Image src="/assets/empty-box.svg" />
              <h3>Your wishlist is empty</h3>
              <p>
                You can add items you do not want to buy yet to your wishlist.
                You can then add them to your cart later
              </p>
              <NavLink to="/shop">
                <Button>Shop now</Button>
              </NavLink>
            </EmptyState>
          )
        ) : (
          <div style={{ textAlign: "center" }}>
            <Ring color={colours[primary].shade["500"]} />
          </div>
        )}
      </Card>
    </div>
  );
}

const EmptyState = styled.div`
  width: 400px;
  margin: auto;

  text-align: center;

  @media (max-width: 576px) {
    width: 100%;
  }
`;
