import React, { useState, useCallback } from "react";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import { Card } from "@theme-ui/components";
import OrdersTable from "./OrdersTable";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import { FloatingButton } from "../../../app/DSL/components/elements/FloatingButton";
import StatusModal from "./StatusModal";

export default function ManageOrders() {
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const toggleSelected = useCallback(
    (id) => {
      if (selectedOrders.includes(id)) {
        setSelectedOrders((prev) => prev.filter((p) => p !== id));
      } else {
        setSelectedOrders((prev) => [...prev, id]);
      }
    },
    [selectedOrders]
  );

  return (
    <div>
      <PageHeaderContainer>Manage Orders</PageHeaderContainer>
      <Card>
        <BlockHeaderContainer title="View Orders" />
        <OrdersTable
          selectedOrders={selectedOrders}
          toggleSelected={toggleSelected}
        />
      </Card>

      <FloatingButton
        onClick={() => setModalOpen(true)}
        show={selectedOrders.length > 0}
      >
        Update Statuses of {selectedOrders.length} order
        {selectedOrders.length === 1 ? "" : "s"}
      </FloatingButton>
      <StatusModal
        orders={selectedOrders}
        open={modalOpen}
        close={() => setModalOpen(false)}
      />
    </div>
  );
}
