import React, { useMemo, useState } from "react";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import { useProducts } from "../../app/context/products";
import styled from "@emotion/styled";
import ProductCard from "./ProductCard";
import { useUserContext } from "../../app/context/auth";
import ProductModal from "./ProductModal";
import { Ring } from "react-awesome-spinners";
import colours from "../../app/DSL/styles/colours";
import { primary } from "../../app/DSL/theme";

export default function ProductsList({ search, sort, numPoints }) {
  const { products } = useProducts();
  const user = useUserContext();
  const [modalItem, setModalItem] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalState, setModalState] = useState();
  const productList = useMemo(
    () =>
      products
        ? products
            .filter((p) => !p.isArchived)
            .filter((p) =>
              numPoints
                ? numPoints === "Available"
                  ? p.minPrice <= user?.points
                  : p.minPrice <= numPoints
                : true
            )
            .filter((p) =>
              search
                ? p.name.toLowerCase().includes(search.toLowerCase())
                : true
            )
            .sort((a, b) => {
              switch (sort?.value) {
                case "default":
                  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                case "price-high":
                  return a.minPrice < b.minPrice ? 1 : -1;
                case "price-low":
                  return a.minPrice > b.minPrice ? 1 : -1;
                default:
                  return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
              }
            })
        : [],
    [products, numPoints, search, sort, user]
  );
  return (
    <div>
      <BlockHeaderContainer
        title={`Showing ${productList?.length} product${
          productList?.length === 1 ? "" : "s"
        }`}
      />
      {products ? (
        <Grid>
          {productList?.map((p, idx) => (
            <ProductCard
              key={idx}
              product={p}
              openAdd={(product) => {
                setModalState("add");
                setModalItem(product);
                setModalOpen(true);
              }}
              openView={(product) => {
                setModalState("view");
                setModalItem(product);
                setModalOpen(true);
              }}
            />
          ))}
        </Grid>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Ring color={colours[primary].shade["500"]} />
        </div>
      )}

      <ProductModal
        product={modalItem}
        close={() => {
          setModalOpen(false);
          setModalItem(null);
        }}
        viewOnly={modalState === "view"}
        open={modalOpen}
      />
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;
  padding: 1em;
  max-height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 1366px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    padding: 0.5em;
  }
`;
