import React from "react";
import { Heading, Button, Divider } from "@theme-ui/components";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";

export default function BlockHeaderContainer({
  title,
  link,
  actionType,
  buttonText,
  action,
  rightComponent,
  classes,
}) {
  return (
    <Header actionType={rightComponent ? true : actionType} className={classes}>
      <Heading>{title}</Heading>
      {rightComponent && rightComponent}
      {actionType === "link" && (
        <NavLink to={link}>
          <Button>{buttonText}</Button>
        </NavLink>
      )}
      {actionType === "button" && (
        <Button onClick={() => action()}>{buttonText}</Button>
      )}
      <FullDivider />
    </Header>
  );
}

const Header = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.actionType ? "1fr 1fr" : "1fr")};
  align-items: center;
  margin-bottom: 1.5em;
  text-transform: capitalize;
  row-gap: 1em;
  div,
  button,
  a {
    text-align: right;
    justify-self: end;
  }
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    div,
    button,
    a {
      text-align: left;
      justify-self: start;
    }
  }
`;

const FullDivider = styled(Divider)`
  grid-column: 1 / span 2;

  @media (max-width: 576px) {
    grid-column: 1 / span 1;
  }
`;
