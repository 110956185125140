import React from "react";
import { Card, Divider, AspectImage } from "@theme-ui/components";
import styled from "@emotion/styled";
import { getObjFromLink } from "../../app/context/helpers";
import "rc-input-number/assets/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  RoundButton,
  StyledLabel,
} from "../../app/DSL/components/elements/styled";
import ReactTooltip from "react-tooltip";

export default function MobileCard({
  item,
  removeItem,
  setModalOpen,
  setModalAction,
  setModalActionType,
  setConfirmItem,
  setModal,
}) {
  return (
    <ProdCard>
      <AspectImage
        ratio={1 / 1}
        src={
          item.productImageUrl
            ? item.productImageUrl
            : "/assets/product-placeholder.gif"
        }
      />
      <div className="description">
        <Divider />
        <h3>{getObjFromLink(item.productLink).name}</h3>
        <StyledLabel
          colour={
            item.discontinued ? "tusk" : item.inStock ? "lightGreen" : "warning"
          }
          textColour="white"
        >
          {item.discontinued
            ? "DISCONTINUED"
            : item.inStock
            ? "IN STOCK"
            : "OUT OF STOCK"}
        </StyledLabel>
        <p>
          <strong>Price:</strong> {item.price} Points
        </p>
        <span>
          {item.inStock && !item.discontinued && (
            <RoundButton
              data-tip="Transfer to cart"
              data-for="actionName"
              onClick={() => {
                setModalOpen(true);
                setConfirmItem(item);
                setModal("transfer");
              }}
              style={{ marginRight: "1em" }}
              variant="secondary"
            >
              <FontAwesomeIcon icon={["fas", "shopping-cart"]} size="lg" />
            </RoundButton>
          )}
          <RoundButton
            data-tip="Remove from wishlist"
            data-for="actionName"
            variant="secondary"
            onClick={() => {
              setModalOpen(true);
              setModalActionType("remove");
              setModalAction(() => removeItem);
              setConfirmItem(item);
              setModal("confirm");
            }}
          >
            <FontAwesomeIcon icon={["fal", "times"]} size="lg" />
          </RoundButton>

          <ReactTooltip id="actionName" effect="solid" />
        </span>
      </div>
    </ProdCard>
  );
}

const ProdCard = styled(Card)`
  display: grid;
  grid-template-rows: auto auto 60px;
  .divider {
    margin-bottom: 1em;
  }

  @media (max-width: 768px) {
    grid-template-rows: auto auto auto;
  }
  :not(:first-of-type) {
    margin-top: 1em;
  }
`;

// const ButtonGrid = styled.div`
//   display: grid;
//   grid-template-columns: 1fr auto;
//   column-gap: 1em;

//   @media (max-width: 768px) {
//     grid-template-columns: 1fr;
//     row-gap: 1em;
//   }
// `;
