import React from "react";
import PageHeaderContainer from "../../../app/DSL/components/layout/PageHeaderContainer";
import { Card, Divider } from "@theme-ui/components";
import styled from "styled-components";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import FeaturedProducts from "./FeaturedProducts";
import OrdersTable from "./OrderTable";
import PopularProducts from "./PopularProducts";
import OrderedProducts from "./OrderedProducts";
import { SeeAllLink } from "../../../app/DSL/components/elements/styled";
import { NavLink } from "react-router-dom";
import DashStats from "./DashStats";
import DashExport from "./DashExport";

export default function Dashboard() {
  return (
    <div>
      <PageHeaderContainer>Admin Dashboard</PageHeaderContainer>
      <DashExport />
      <DashStats />
      <Grid>
        <Card style={{ position: "relative" }}>
          <BlockHeaderContainer title="New Orders" />
          <OrdersTable />
          <SeeAllLink>
            <Divider />
            <NavLink to="/admin-area/orders">See all</NavLink>
          </SeeAllLink>
        </Card>
        <Card style={{ position: "relative" }}>
          <BlockHeaderContainer title="Featured products" />
          <h4>
            Note that only the first 3 featured products will be displayed on
            the spotlight page. If no products are featured 3 will be randomly
            selected
          </h4>
          <FeaturedProducts />
          <SeeAllLink>
            <Divider />
            <NavLink to="/admin-area/products">See all</NavLink>
          </SeeAllLink>
        </Card>
      </Grid>
      <BottomGrid>
        <PopularProducts />
        <OrderedProducts />
      </BottomGrid>
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 1em;
  row-gap: 1em;
  @media (max-width: 1366px) {
    grid-template-columns: 1fr;
  }
`;

const BottomGrid = styled.div`
  margin-top: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1em;
  row-gap: 1em;
  @media (max-width: 1366px) {
    grid-template-columns: 1fr;
  }
`;
