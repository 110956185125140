import React, { useState, useEffect, useMemo } from "react";
import CardModal from "../../app/DSL/components/elements/CardModal";
import styled from "@emotion/styled";
import { Image, Label, Divider, Flex, Button } from "@theme-ui/components";
import { InputWrap } from "../../app/DSL/components/form/StyledInput";
import Select from "react-select";
import { customStyles } from "../../app/forms/Form";
import { useCart } from "../../app/context/cart";
import InputNum from "rc-input-number";
import "rc-input-number/assets/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useToasts } from "react-toast-notifications";
import { formatPointString } from "../../app/context/points/formatting";

export default function ProductModal({ open, close, product, viewOnly }) {
  const [item, setItem] = useState();
  const [quantity, setQuantity] = useState(1);
  const {
    addToCart,
    addToWishlist,
    wishlistItemIds,
    removeFromWishlist,
  } = useCart();

  const { addToast } = useToasts();

  useEffect(() => {
    if ((item === undefined || item === null) && product?.items) {
      setItem({ label: product.items[0].size, value: product.items[0] });
    }
  }, [product, item]);

  useEffect(() => {
    if (product === undefined || product === null) {
      setItem(null);
    }
  }, [product]);

  const items = useMemo(
    () => product?.items.map((i) => ({ label: i.size, value: i })),
    [product]
  );
  const doClose = () => {
    setItem(null);
    setQuantity(1);
    close();
  };

  return product ? (
    <CardModal isOpen={open} close={doClose}>
      <Grid>
        <Image
          src={
            product.productImageUrl
              ? product.productImageUrl
              : "/assets/product-placeholder.gif"
          }
        />
        <div>
          <h2>{product.name}</h2>
          <Divider />
          <p
            dangerouslySetInnerHTML={{
              __html: product.description,
            }}
          ></p>
          {product.stockType === "variable" && item && (
            <InputWrap style={{ margin: "1em 0 2em" }}>
              <Label>Size</Label>

              <Select
                styles={customStyles}
                isClearable
                isSearchable
                options={items}
                value={item}
                onChange={(option) => setItem(option)}
              />
            </InputWrap>
          )}
          {!viewOnly && (
            <InputWrap style={{ margin: "1em 0 2em" }}>
              <Label>Quantity</Label>
              <InputNum
                min={1}
                max={10}
                step={1}
                value={quantity}
                style={{ width: "100%" }}
                onChange={(num) => {
                  setQuantity(num);
                }}
              />
            </InputWrap>
          )}

          <h3>
            {formatPointString(
              item?.value.price * (quantity > 0 ? quantity : 1)
            )}{" "}
            Points
          </h3>
          {item?.value.inStock &&
            !viewOnly &&
            (wishlistItemIds.includes(product.id) ? (
              <Button
                onClick={() =>
                  removeFromWishlist(product).then(() => {
                    addToast(`${product.name} removed from wishlist`, {
                      appearance: "success",
                      autoDismiss: true,
                      autoDismissTimeout: 3000,
                    });
                  })
                }
              >
                <FontAwesomeIcon icon={["fas", "heart"]} /> Remove from wishlist
              </Button>
            ) : (
              <Button
                variant="outline"
                onClick={() =>
                  addToWishlist(product).then((status) => {
                    const message =
                      status === "added"
                        ? `${product.name} ${
                            product.stockType === "variable"
                              ? ` - ${item.value.size}`
                              : ""
                          } added to wishlist`
                        : `${product.name}  ${
                            product.stockType === "variable"
                              ? ` - ${item.value.size}`
                              : ""
                          } already in wishlist`;
                    doClose();
                    addToast(message, {
                      appearance: status === "added" ? "success" : "warning",
                      autoDismiss: true,
                      autoDismissTimeout: 3000,
                    });
                  })
                }
              >
                <FontAwesomeIcon icon={["fal", "heart"]} /> Add to wishlist
              </Button>
            ))}
        </div>
      </Grid>
      {viewOnly ? (
        <Flex style={{ justifyContent: "flex-end", marginTop: "1em" }}>
          <Button onClick={doClose} variant="outline">
            Close
          </Button>
        </Flex>
      ) : (
        <Flex
          style={{
            justifyContent: "space-between",
            marginTop: "1em",
          }}
        >
          <Button onClick={doClose} variant="outline">
            Cancel
          </Button>
          <Button
            variant={!item?.value.inStock ? "disabled" : "primary"}
            onClick={() =>
              addToCart({
                ...product,
                ...item.value,
                quantity,
              }).then((status) => {
                const message =
                  status === "added"
                    ? `${product.name} ${
                        product.stockType === "variable"
                          ? ` - ${item.value.size}`
                          : ""
                      } added to cart`
                    : `${product.name}  ${
                        product.stockType === "variable"
                          ? ` - ${item.value.size}`
                          : ""
                      } already in cart`;
                doClose();
                addToast(message, {
                  appearance: status === "added" ? "success" : "warning",
                  autoDismiss: true,
                  autoDismissTimeout: 3000,
                });
              })
            }
          >
            <FontAwesomeIcon icon={["fal", "cart-plus"]} /> Add to cart
          </Button>
        </Flex>
      )}
    </CardModal>
  ) : null;
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2em;
  row-gap: 1em;
  min-height: 60vh;
  align-items: center;

  @media (max-width: 568px) {
    grid-template-columns: 1fr;
  }
`;
