const description = ({ reason, numPoints }) => {
  switch (reason) {
    case "order__placed":
      return `An order of ${formatPointString(numPoints)} points was placed`;
    case "order__refunded":
      return `An order of ${formatPointString(numPoints)} points was refunded`;

    default:
      return "";
  }
};

export const pointReport = ({ reason, numPoints, userLink }) => {
  return {
    reason,
    numPoints: numPoints,
    dateReceived: new Date(),
    description: description({ reason, numPoints }),
    userLink,
  };
};

export const formatPointString = (points, format) => {
  return points
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, format === "spaces" ? " " : ",");
};
