import React from "react";
import { Card } from "@theme-ui/components";
import styled from "@emotion/styled";
import { format } from "date-fns";
import StatusLabel from "../../app/DSL/components/elements/StatusLabel";
import { primary } from "../../app/DSL/theme";
import Collapse from "@kunukn/react-collapse";
import MobileBreakdown from "./MobileBreakdown";

export default function OrderCard({ order, setOrder, active }) {
  return (
    <StyledOrderCard
      onClick={() => {
        active ? setOrder(null) : setOrder(order);
      }}
      style={{ marginBottom: "1em" }}
      active={active}
    >
      <Grid>
        <div>
          <h3 style={{ margin: 0 }}>
            Order #{order.id.slice(0, 6).toUpperCase()}
          </h3>
          <p style={{ marginBottom: 0 }}>
            {format(order.dateCreated.toDate(), "dd MMMM yyyy")}
          </p>
        </div>
        <div>
          <StatusLabel status={order.status} active={active} />
        </div>
      </Grid>

      <Collapse isOpen={active}>
        <div class="mobile breakdown-card">
          <Card>
            <MobileBreakdown order={order} />
          </Card>
        </div>
      </Collapse>
    </StyledOrderCard>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

const StyledOrderCard = styled(Card)`
  padding: 1em;
  color: ${(props) => {
    if (props.active) {
      return "white";
    } else {
      return "black";
    }
  }};
  background: ${(props) => {
    if (props.active) {
      return props.theme.colors[primary].shade["500"];
    } else {
      return "white";
    }
  }};
  transition: background 0.3s ease;
  .breakdown-card {
    > div {
      padding: 1em 2em;
      margin-top: 1em;
    }
  }
  @media (max-width: 576px) {
    .breakdown-card {
      > div {
        padding: 1em;
      }
    }
  }
`;
