import React, { useState } from "react";
import CardModal from "../../../app/DSL/components/elements/CardModal";
import ProductForm from "./ProductForm/index.jsx";
import { useProducts } from "../../../app/context/products";
import BlockHeaderContainer from "../../../app/DSL/components/layout/BlockHeaderContainer";
import ProductImage from "./ProductForm/ProductImage";
import { useToasts } from "react-toast-notifications";

export default function EditProductModal({ open, close, product }) {
  const { updateProduct } = useProducts();
  //TODO: use loading and error
  //const [loading, setLoading] = useState(false);
  //const [error, setError] = useState();
  const [step, setStep] = useState(1);
  const { addToast } = useToasts();
  const submit = (e, formattedValues, reset) => {
    console.log("hello");
    e.preventDefault();
    updateProduct(product.id, formattedValues).then(() => {
      reset();
      setStep(2);
    });
  };
  const submitImage = (url) => {
    updateProduct(product.id, { productImageUrl: url }).then(() => {
      addToast(`Product Updated`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      close();
      setStep(1);
    });
  };

  return (
    <CardModal
      isOpen={open}
      close={() => {
        setStep(1);
        close();
      }}
    >
      <BlockHeaderContainer title="Edit new product" />
      {step === 1 && (
        <ProductForm
          initialValues={product}
          submit={submit}
          loading={false}
          error={false}
          submitText="Next"
          cancel={() => {
            setStep(1);
            close();
          }}
        />
      )}
      {step === 2 && (
        <ProductImage
          close={() => {
            setStep(1);
            close();
          }}
          submitImage={submitImage}
          submitText="Update"
          product={product}
        />
      )}
    </CardModal>
  );
}
