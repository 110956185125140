import React, { createContext, useContext, useState, useEffect } from "react";
import { transformCollectionSnap } from "../helpers";
import { useFirebase } from "../Firebase";
import { useAuthContext, useUserContext } from "../auth";
import { formatUpdateProduct, formatCreateProduct } from "./formatting.js";

const ProductsContext = createContext({});

export const ProductsProvider = ({ children }) => {
  const firebase = useFirebase();
  const [products, setProducts] = useState();
  const auth = useAuthContext();
  const user = useUserContext();

  useEffect(() => {
    const unsubscribe = auth.user
      ? firebase.products().onSnapshot((snap) =>
          setProducts(
            transformCollectionSnap(snap).map((p) => ({
              ...p,
              minPrice: Math.min(...p.items.map((i) => i.price)),
              inStock: p.items.filter((i) => i.inStock).length > 0,
            }))
          )
        )
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, auth]);

  const archiveProducts = (products) => {
    const batch = firebase.db.batch();
    products.forEach((p) => {
      batch.update(
        firebase.product(p),
        formatUpdateProduct({ isArchived: true }, user)
      );
    });
    return batch.commit();
  };

  const featureProducts = (products) => {
    const batch = firebase.db.batch();
    products.forEach((p) => {
      batch.update(
        firebase.product(p),
        formatUpdateProduct({ isFeatured: true }, user)
      );
    });
    return batch.commit();
  };

  const createProduct = (values) => {
    return firebase.products().add(formatCreateProduct(values, user));
  };

  const updateProduct = (id, values) => {
    return firebase.product(id).update(formatUpdateProduct(values, user));
  };

  return (
    <ProductsContext.Provider
      value={{
        products,
        archiveProducts,
        createProduct,
        updateProduct,
        featureProducts,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export const ProductsConsumer = ProductsContext.Consumer;

export const useProducts = () => useContext(ProductsContext);
