export const transformCollectionSnap = snap => {
  return snap.docs.map(doc => {
    const data = doc.data();

    return { id: doc.id, ...data };
  });
};

export const transformDocSnap = snap => {
  const data = snap.data();

  return { id: snap.id, ...data };
};

export const getObjFromLink = link => {
  const sep = link.indexOf("__");
  const id = link.substring(0, sep);
  const name = link.substring(sep + 2);
  return { id, name };
};
