import styled from "@emotion/styled";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useCarts } from "../../../app/context/carts";
import { useFirebase } from "../../../app/context/Firebase";
import {
  getObjFromLink,
  transformCollectionSnap,
} from "../../../app/context/helpers";
import { useProducts } from "../../../app/context/products";

import { CSVLink, CSVDownload } from "react-csv";
import { useOrders } from "../../../app/context/orders";
import { format, isBefore } from "date-fns";
import { useStats } from "../../../app/context/stats";

const DashExport = () => {
  const firebase = useFirebase();

  const { products } = useProducts();
  const { wishlists, carts } = useCarts();
  const { orders, activeOrderedItemsWithSizes } = useOrders();
  const [users, setUsers] = useState([]);
  const { logins } = useStats();
  const [data, setData] = useState();
  const [headers, setHeaders] = useState();

  const csvWishlistHeaders = [
    { label: "Name", key: "username" },
    { label: "Points Balance", key: "points" },
    { label: "Products", key: "productName" },
    { label: "Total Value", key: "totalPrice" },
  ];
  const csvOrdersHeaders = [
    { label: "Order Number", key: "orderNumber" },
    { label: "Name", key: "username" },
    { label: "Date ordered", key: "date" },
    { label: "Status", key: "status" },
    { label: "Items", key: "products" },
    { label: "Total Price", key: "totalPrice" },
  ];
  const csvProductHeaders = [
    { label: "ID", key: "id" },
    { label: "Name - size", key: "name" },
    { label: "Count", key: "count" },
  ];

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection("users")
      .onSnapshot(
        (snapshot) => {
          setUsers(transformCollectionSnap(snapshot));
        },
        (err) => {
          console.log("error");
        }
      );

    return () => unsubscribe();
  }, [firebase, wishlists, products]);

  const formattedWishlistData = useMemo(() => {
    if (wishlists && products && users) {
      const lists = wishlists?.map((wishlist) => {
        const user = users.find((obj) => {
          return obj.id === wishlist.id;
        });
        const prods = wishlist.items.map(
          (p) => getObjFromLink(p.productLink).name
        );
        const totalPrice = wishlist.items.reduce((tot, cur) => {
          const product = products.find(
            (prod) => prod.id === getObjFromLink(cur.productLink).id
          );
          return (tot += product.minPrice);
        }, 0);

        return {
          id: wishlist.id,
          username: `${user?.firstName} ${user?.lastName}`,
          points: user?.points,
          products: prods,
          totalPrice,
        };
      });

      return lists;
    } else return [];
  }, [wishlists, products, users]);

  const formattedCartData = useMemo(() => {
    if (carts && products && users) {
      const lists = carts?.map((cart) => {
        const user = users.find((obj) => {
          return obj.id === cart.id;
        });
        const prods = cart.items.map(
          (p) => `${getObjFromLink(p.productLink).name} (${p.quantity})`
        );
        const totalPrice = cart.items.reduce((tot, cur) => {
          const product = products.find(
            (prod) => prod.id === getObjFromLink(cur.productLink).id
          );
          const item = product?.items.find((i) => i.size === cur.size);

          return (tot += item?.price * cur?.quantity || 0);
        }, 0);

        return {
          id: cart.id,
          username: `${user?.firstName} ${user?.lastName}`,
          points: user?.points,
          products: prods,
          totalPrice,
        };
      });

      return lists;
    } else return [];
  }, [carts, products, users]);

  const formattedOrderData = useMemo(
    () =>
      orders
        ? orders
            .sort((a, b) =>
              isBefore(a?.dateCreated?.toDate(), b?.dateCreated?.toDate())
                ? 1
                : -1
            )
            .map((o) => ({
              ...o,
              orderNumber: `#${o.id.slice(0, 6).toUpperCase()}`,
              username: o.placedBy.name,
              date: o?.dateCreated
                ? format(o?.dateCreated.toDate(), "dd MMMM yyyy")
                : "-",
              products: o.items.map(
                (i) => `${getObjFromLink(i.productLink).name}
                ${i.size !== "fixed" ? ` -${i.size}` : ""} (${i.quantity})`
              ),
            }))
        : [],
    [orders]
  );
  const formattedProducts = useMemo(
    () =>
      activeOrderedItemsWithSizes
        ? activeOrderedItemsWithSizes.map((item) => ({
            ...getObjFromLink(item.productLink),
            ...item,
          }))
        : [],
    [activeOrderedItemsWithSizes]
  );

  return (
    <ExportGrid>
      {/* <ExportButton
        onClick={() => {
          console.log(users);
        }}
      >
        Log All Users
      </ExportButton>
      <ExportButton
        onClick={() => {
          console.log(wishlists);
        }}
      >
        Log All Wishlists
      </ExportButton>
      <ExportButton
        onClick={() => {
          console.log(products);
        }}
      >
        Log All Products
      </ExportButton> */}

      <ExportButton>
        <CSVLink
          data={formattedWishlistData}
          target="_blank"
          headers={csvWishlistHeaders}
          filename={"Wishlist-Export.csv"}
        >
          Download Wishlist CSV
        </CSVLink>
      </ExportButton>
      <ExportButton>
        <CSVLink
          data={formattedCartData}
          target="_blank"
          headers={csvWishlistHeaders}
          filename={"Cart-Export.csv"}
        >
          Download Cart CSV
        </CSVLink>
      </ExportButton>
      <ExportButton>
        <CSVLink
          data={formattedOrderData}
          target="_blank"
          headers={csvOrdersHeaders}
          filename={"Download-Export.csv"}
        >
          Download Orders CSV
        </CSVLink>
      </ExportButton>
      <ExportButton>
        <CSVLink
          data={formattedProducts}
          target="_blank"
          headers={csvProductHeaders}
          filename={"Download-Export.csv"}
        >
          Download Products CSV
        </CSVLink>
      </ExportButton>
    </ExportGrid>
  );
};

const ExportButton = styled.div`
  background-color: #005470;
  color: #fff;

  border-radius: 8px;
  cursor: pointer;

  padding: 8px 20px;

  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: #005b79;
  }
`;

const ExportGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 1em;

  @media (max-width: 1025px) {
    grid-template-columns: 1fr 1fr;
  }

  margin-bottom: 1em;
`;

export default DashExport;
