import React from "react";
import { Card, Divider, AspectImage, Label } from "@theme-ui/components";
import styled from "@emotion/styled";
import { getObjFromLink } from "../../app/context/helpers";
import { InputWrap } from "../../app/DSL/components/form/StyledInput";
import InputNum from "rc-input-number";
import "rc-input-number/assets/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  RoundButton,
  StyledLabel,
} from "../../app/DSL/components/elements/styled";
import ReactTooltip from "react-tooltip";

export default function MobileCard({
  item,
  updateQty,
  removeItem,
  transferToWishlist,
  setModalOpen,
  setModalAction,
  setModalActionType,
  setConfirmItem,
}) {
  return (
    <ProdCard>
      <AspectImage
        ratio={1 / 1}
        src={
          item.productImageUrl
            ? item.productImageUrl
            : "/assets/product-placeholder.gif"
        }
      />
      <div className="description">
        <Divider />
        <h3>
          {getObjFromLink(item.productLink).name}
          {item.size !== "fixed" ? `  (${item.size})` : ""}
        </h3>
        <StyledLabel
          colour={
            item.discontinued ? "tusk" : item.inStock ? "lightGreen" : "warning"
          }
          textColour="white"
        >
          {item.discontinued
            ? "DISCONTINUED"
            : item.inStock
            ? "IN STOCK"
            : "OUT OF STOCK"}
        </StyledLabel>
        <p>
          <strong>Price:</strong> {item.price} Points
        </p>
        <InputWrap>
          {" "}
          <Label>Quantity</Label>
          <InputNum
            min={1}
            max={10}
            step={1}
            value={item.quantity}
            style={{ width: "100%" }}
            onChange={(num) => {
              updateQty(num, item);
            }}
          />
        </InputWrap>

        <p>
          <strong>Total:</strong> {item.price * item.quantity} Points
        </p>
        <RoundButton
          data-tip="Transfer to wishlist"
          data-for="actionName"
          onClick={() => {
            setModalOpen(true);
            setModalActionType("transfer");
            setModalAction(() => transferToWishlist);
            setConfirmItem(item);
          }}
          style={{ marginRight: "1em" }}
          variant="secondary"
        >
          <FontAwesomeIcon icon={["fas", "heart"]} size="lg" />
        </RoundButton>
        <RoundButton
          data-tip="Remove from cart"
          data-for="actionName"
          variant="secondary"
          onClick={() => {
            setModalOpen(true);
            setModalActionType("remove");
            setModalAction(() => removeItem);
            setConfirmItem(item);
          }}
        >
          <FontAwesomeIcon icon={["fal", "times"]} size="lg" />
        </RoundButton>
        <ReactTooltip id="actionName" effect="solid" />
      </div>
    </ProdCard>
  );
}

const ProdCard = styled(Card)`
  display: grid;
  grid-template-rows: auto auto 60px;
  .divider {
    margin-bottom: 1em;
  }

  @media (max-width: 768px) {
    grid-template-rows: auto auto auto;
  }
  :not(:first-of-type) {
    margin-top: 1em;
  }
`;

// const ButtonGrid = styled.div`
//     display: grid;
//     grid-template-columns: 1fr auto;
//     column-gap: 1em;

//     @media (max-width: 768px) {
//         grid-template-columns: 1fr;
//         row-gap: 1em;
//     }
// `;
