import React, { useState, useMemo } from "react";
import styled from "@emotion/styled";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import { Card, Button, Image } from "@theme-ui/components";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import { useCart } from "../../app/context/cart";
import { useUserContext } from "../../app/context/auth";
import { formatPointString } from "../../app/context/points/formatting";
import CartTable from "./CartTable";
import ConfirmCheckoutModal from "./ConfirmCheckoutModal";
import { useToasts } from "react-toast-notifications";
import colours from "../../app/DSL/styles/colours";
import { primary } from "../../app/DSL/theme";
import { Ring } from "react-awesome-spinners";
import { NavLink } from "react-router-dom";
export default function Cart() {
  const { cart, placeOrder } = useCart();
  const [modalOpen, setModalOpen] = useState(false);
  const user = useUserContext();
  const { addToast } = useToasts();

  const totalPrice = useMemo(
    () =>
      cart && cart.items
        ? cart?.items
            .filter((i) => i.inStock && !i.discontinued)
            ?.map((q) => q.quantity * q.price)
            .reduce((cur, tot) => (tot += cur), 0)
        : 0,
    [cart]
  );

  const doPlaceOrder = () => {
    return placeOrder({
      items: cart.items.filter((i) => i.inStock && !i.discontinued),
      totalPrice,
    }).then(() => {
      setModalOpen(false);
      addToast(`Order Placed`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    });
  };
  const outOfStock = useMemo(() => {
    return cart?.items.filter((i) => !i.inStock);
  }, [cart]);

  const discontinued = useMemo(() => {
    return cart?.items.filter((i) => i.discontinued);
  }, [cart]);

  const canCheckout = useMemo(() => {
    return totalPrice > 0 && totalPrice < user?.points;
  }, [totalPrice, user]);

  return (
    <div>
      <PageHeaderContainer>Cart</PageHeaderContainer>
      <Grid>
        <Card>
          <BlockHeaderContainer
            title={`Your cart contains ${
              cart?.items.length ? cart?.items.length : 0
            } item${cart?.items.length === 1 ? "" : "s"}`}
          />
          {cart?.items ? (
            cart?.items.length > 0 ? (
              <CartTable />
            ) : (
              <EmptyState>
                <Image src="/assets/empty-cart.svg" />
                <h3>Your cart is empty</h3>

                <p>
                  You can transfer wishlist items to your cart to purchase them
                </p>
                <NavLink to="/shop">
                  <Button>Shop now</Button>
                </NavLink>
              </EmptyState>
            )
          ) : (
            <div style={{ textAlign: "center" }}>
              <Ring color={colours[primary].shade["500"]} />
            </div>
          )}
        </Card>
        <Card>
          <BlockHeaderContainer title="cart total" />
          <h1>{totalPrice ? formatPointString(totalPrice) : 0} points</h1>
          {totalPrice > 0 && user?.points ? (
            totalPrice > user?.points ? (
              <div>
                <h3>You do not have enough points!</h3>
                <p>
                  Please remove{" "}
                  {user?.points && totalPrice
                    ? formatPointString(totalPrice - user?.points)
                    : 0}{" "}
                  from your cart to proceed with checkout{" "}
                </p>
              </div>
            ) : (
              <div>
                <p>
                  You will have{" "}
                  {user?.points && totalPrice
                    ? formatPointString(user?.points - totalPrice)
                    : 0}{" "}
                  points after checkout
                </p>
              </div>
            )
          ) : (
            <div>
              <h3>Your cart is empty</h3>
              <p>Add items to your cart to proceed with checkout</p>
            </div>
          )}

          {outOfStock?.length > 0 && (
            <div>
              <h3>
                {outOfStock.length} {outOfStock.length === 1 ? "item" : "items"}{" "}
                in your cart {outOfStock.length === 1 ? "is" : "are"} out of
                stock
              </h3>
              <p>
                {outOfStock.length === 1 ? "This item" : "These items"} will not
                be included in your order. You can transfer{" "}
                {outOfStock.length === 1 ? "it" : "them"} to your wishlist to
                check back on later.
              </p>
            </div>
          )}
          {discontinued?.length > 0 && (
            <div>
              <h3>
                {discontinued.length}{" "}
                {discontinued.length === 1 ? "item" : "items"} in your cart{" "}
                {discontinued.length === 1 ? "is" : "are"} discontinued
              </h3>
              <p>
                {discontinued.length === 1 ? "This item" : "These items"} will
                not be included in your order.
              </p>
            </div>
          )}
          <Button
            variant={canCheckout ? "primary" : "disabled"}
            onClick={() => (canCheckout ? setModalOpen(true) : {})}
          >
            Checkout
          </Button>
        </Card>
      </Grid>
      <ConfirmCheckoutModal
        width="450px"
        open={modalOpen}
        close={() => setModalOpen(false)}
        placeOrder={doPlaceOrder}
        totalPrice={totalPrice}
      />
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 1em;
  align-items: start;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;

const EmptyState = styled.div`
  width: 350px;
  margin: auto;

  text-align: center;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const MessageCard = styled(Card)`
  padding: 1em 2em;
  margin: 1em 0;
  background: ${(props) => props.theme.colors[primary].shade[500]};
  color: ${(props) => props.theme.colors[primary].font[500]};
`;
