import React, { useState, useMemo } from "react";
import styled from "@emotion/styled";
import { Card, Image } from "@theme-ui/components";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import { useUserContext } from "../../app/context/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatPointString } from "../../app/context/points/formatting";
import { NavLink } from "react-router-dom";
import { useProducts } from "../../app/context/products";
import FeaturedCard from "./FeaturedCard";
import ProductModal from "../Products/ProductModal";
import Slider from "./Slider";
import { Ring } from "react-awesome-spinners";
import colours from "../../app/DSL/styles/colours";
import { primary } from "../../app/DSL/theme";
import { useCart } from "../../app/context/cart";
const Spotlight = () => {
  const [modalItem, setModalItem] = useState();
  const [modalOpen, setModalOpen] = useState();
  const user = useUserContext();
  const { cart, wishlist } = useCart();

  const { products } = useProducts();
  const featuredProducts = useMemo(() => {
    if (products) {
      const prods = products
        ?.filter((p) => !p.isArchived)
        .filter((p) => p.isFeatured);
      if (prods.length < 3) {
        return [...prods, ...products?.slice(0, 3 - prods.length)];
      } else {
        return prods;
      }
    } else {
      return [];
    }
  }, [products]);
  return (
    <div>
      <PageHeaderContainer image>
        <Image src="/assets/long-logo.svg" width="450px" />
      </PageHeaderContainer>

      <TopGrid>
        <Card>
          <BlockHeaderContainer title={`Welcome ${user?.displayName}`} />
          <Grid>
            <Card variant="small">
              <Points>
                <h1>
                  <FontAwesomeIcon icon={["far", "star"]} />{" "}
                  {user?.points && formatPointString(user.points, "spaces")}{" "}
                </h1>
                <h2>POINTS</h2>
              </Points>
            </Card>
            <NavLink to="/cart">
              <Card variant="small">
                <Points>
                  <h1 className="cart">
                    <FontAwesomeIcon icon={["far", "shopping-cart"]} />{" "}
                    {cart?.items?.length ? cart?.items?.length : 0}
                  </h1>
                  <h3>Items in cart</h3>
                </Points>
              </Card>
            </NavLink>
            <NavLink to="/wishlist">
              <Card variant="small">
                <Points>
                  <h1 className="wishlist">
                    <FontAwesomeIcon icon={["far", "heart"]} />{" "}
                    {wishlist?.items?.length > 0 ? wishlist?.items?.length : 0}
                  </h1>
                  <h3>Items in wishlist</h3>
                </Points>
              </Card>
            </NavLink>
          </Grid>
        </Card>
        <Card>
          <Slider />
        </Card>
      </TopGrid>

      <Card>
        <BlockHeaderContainer title="Featured products"></BlockHeaderContainer>
        {products ? (
          <ProductGrid>
            {[
              featuredProducts.slice(0, 3).map((p, idx) => (
                <FeaturedCard
                  product={p}
                  key={idx}
                  action={() => {
                    setModalOpen(true);
                    setModalItem(p);
                  }}
                  buttonText="View"
                />
              )),
              <NavLink to="/shop" key="view">
                <FontAwesomeIcon
                  icon={["fal", "angle-right"]}
                  size="2x"
                  style={{
                    textAlign: "center",
                    width: "100%",
                  }}
                />{" "}
                <p
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontSize: "0.7em",
                  }}
                >
                  View All
                </p>
              </NavLink>,
            ]}
          </ProductGrid>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Ring color={colours[primary].shade["500"]} />
          </div>
        )}
      </Card>
      <ProductModal
        product={modalItem}
        close={() => setModalOpen(false)}
        viewOnly={true}
        open={modalOpen}
      />
    </div>
  );
};

export default Spotlight;

const Grid = styled.div`
  display: grid;
  column-gap: 1em;
  row-gap: 1em;
  grid-template-columns: 1fr;
  @media (max-width: 1366px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  > * {
    height: 100%;
  }
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 50px;
  column-gap: 1em;
  align-items: center;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    row-gap: 1em;
  }
`;

const TopGrid = styled.div`
  display: grid;
  grid-template-columns: 2fr 5fr;
  column-gap: 1em;
  row-gap: 1em;
  margin-bottom: 1em;
  @media (max-width: 1366px) {
    grid-template-columns: 1fr;
  }
`;
const Points = styled.div`
  text-align: center;
  h1 {
    svg {
      color: ${(props) => props.theme.colors.orange.shade["500"]};
      margin-right: 5px;
    }
    font-size: 3em;
    margin-bottom: 0;
    margin-top: 1rem;
  }
  h2 {
    font-size: 2em;
    margin-top: 0;
  }
  h3 {
    font-size: 1.5em;
    margin-top: 0.5rem;
  }

  .cart {
    svg {
      color: ${(props) => props.theme.colors[primary].shade["500"]};
    }
  }

  .wishlist {
    svg {
      color: ${(props) => props.theme.colors.warning.shade["500"]};
    }
  }
`;

const MessageCard = styled(Card)`
  padding: 1em 2em;
  margin: 1em 0;
  background: ${(props) => props.theme.colors[primary].shade[500]};
  color: ${(props) => props.theme.colors[primary].font[500]};
`;
