const whiteText = {
  "100": "#fff",
  "200": "#fff",
  "300": "#fff",
  "400": "#fff",
  "500": "#fff",
  "600": "#fff",
  "700": "#fff",
  "800": "#fff",
  "900": "#fff",
};

const blackText = {
  "100": "#000",
  "200": "#000",
  "300": "#000",
  "400": "#000",
  "500": "#000",
  "600": "#000",
  "700": "#000",
  "800": "#000",
  "900": "#000",
};

const colours = {
  darkBlue: {
    shade: {
      "100": "#3D3BB4",
      "200": "#3937A9",
      "300": "#35349D",
      "400": "#302F8E",
      "500": "#2B2A80",
      "600": "#25256F",
      "700": "#201F60",
      "800": "#1B1A51",
      "900": "#13133A",
    },
    half: {
      "100": "#3D3BB480",
      "200": "#3937A980",
      "300": "#35349D80",
      "400": "#302F8E80",
      "500": "#2B2A8080",
      "600": "#25256F80",
      "700": "#201F6080",
      "800": "#1B1A5180",
      "900": "#13133A80",
    },
    font: whiteText,
  },
  blue: {
    shade: {
      "100": "#009AF0",
      "200": "#0093E5",
      "300": "#008DDB",
      "400": "#0086D1",
      "500": "#0081C9",
      "600": "#0079BD",
      "700": "#0073B2",
      "800": "#006CA8",
      "900": "#00659E",
    },
    half: {
      "100": "#009AF080",
      "200": "#0093E580",
      "300": "#008DDB80",
      "400": "#0086D180",
      "500": "#0081C980",
      "600": "#0079BD80",
      "700": "#0073B280",
      "800": "#006CA880",
      "900": "#00659E80",
    },
    font: {
      ...blackText,
      "600": "#fff",
      "700": "#fff",
      "800": "#fff",
      "900": "#fff",
    },
  },
  teal: {
    shade: {
      "100": "#007BA3",
      "200": "#007399",
      "300": "#006B8F",
      "400": "#006485",
      "500": "#005B79",
      "600": "#005470",
      "700": "#004D66",
      "800": "#00455C",
      "900": "#003D52",
    },
    half: {
      "100": "#007BA380",
      "200": "#00739980",
      "300": "#006B8F80",
      "400": "#00648580",
      "500": "#005B7980",
      "600": "#00547080",
      "700": "#004D6680",
      "800": "#00455C80",
      "900": "#003D5280",
    },
    font: whiteText,
  },
  lightTeal: {
    shade: {
      "100": "#00D1C5",
      "200": "#00C7BB",
      "300": "#00BDB1",
      "400": "#00B2A8",
      "500": "#00A79D",
      "600": "#009E95",
      "700": "#00948B",
      "800": "#008A81",
      "900": "#008078",
    },
    half: {
      "100": "#00D1C580",
      "200": "#00C7BB80",
      "300": "#00BDB180",
      "400": "#00B2A880",
      "500": "#00A79D80",
      "600": "#009E9580",
      "700": "#00948B80",
      "800": "#008A8180",
      "900": "#00807880",
    },
    font: { ...blackText, "900": "#fff" },
  },
  tusk: {
    shade: {
      "100": "#F9F9FA",
      "200": "#F4F4F6",
      "300": "#EEEEF1",
      "400": "#E9E9ED",
      "500": "#E2E2E7",
      "600": "#DDDDE3",
      "700": "#D8D8DF",
      "800": "#D2D2DA",
      "900": "#CDCDD6",
    },
    font: blackText,
  },
  midnight: {
    shade: {
      "100": "#5D636F",
      "200": "#565C67",
      "300": "#515761",
      "400": "#4D525C",
      "500": "#474C55",
      "600": "#434851",
      "700": "#3F434B",
      "800": "#383C43",
      "900": "#31343A",
    },
    font: whiteText,
  },
  purple: {
    shade: {
      "100": "#893CC3",
      "200": "#7E38B3",
      "300": "#7634A7",
      "400": "#6E309C",
      "500": "#662D91",
      "600": "#5D2984",
      "700": "#58277D",
      "800": "#522475",
      "900": "#471F65",
    },
    half: {
      "100": "#893CC380",
      "200": "#7E38B380",
      "300": "#7634A780",
      "400": "#6E309C80",
      "500": "#662D9180",
      "600": "#5D298480",
      "700": "#58277D80",
      "800": "#52247580",
      "900": "#471F6580",
    },
    font: whiteText,
  },
  magenta: {
    shade: {
      "100": "#D624A9",
      "200": "#CD22A2",
      "300": "#C5219B",
      "400": "#BC1F94",
      "500": "#B41E8E",
      "600": "#AA1C86",
      "700": "#A21B80",
      "800": "#991979",
      "900": "#901872",
    },
    half: {
      "100": "#D624A980",
      "200": "#CD22A280",
      "300": "#C5219B80",
      "400": "#BC1F9480",
      "500": "#B41E8E80",
      "600": "#AA1C8680",
      "700": "#A21B8080",
      "800": "#99197980",
      "900": "#90187280",
    },
    font: { ...whiteText, "100": "#000" },
  },
  green: {
    shade: {
      "100": "#008F4E",
      "200": "#008548",
      "300": "#007A43",
      "400": "#00703D",
      "500": "#006738",
      "600": "#005C32",
      "700": "#00572F",
      "800": "#004D2A",
      "900": "#004224",
    },
    half: {
      "100": "#008F4E80",
      "200": "#00854880",
      "300": "#007A4380",
      "400": "#00703D80",
      "500": "#00673880",
      "600": "#005C3280",
      "700": "#00572F80",
      "800": "#004D2A80",
      "900": "#00422480",
    },
    font: { ...whiteText, "100": "#000" },
  },
  lightGreen: {
    shade: {
      "100": "#A3CD65",
      "200": "#9CC959",
      "300": "#95C64E",
      "400": "#8EC242",
      "500": "#89BD3D",
      "600": "#83B53A",
      "700": "#7EAE38",
      "800": "#78A636",
      "900": "#739E33",
    },
    half: {
      "100": "#A3CD6580",
      "200": "#9CC95980",
      "300": "#95C64E80",
      "400": "#8EC24280",
      "500": "#89BD3D80",
      "600": "#83B53A80",
      "700": "#7EAE3880",
      "800": "#78A63680",
      "900": "#739E3380",
    },
    font: blackText,
  },
  orange: {
    shade: {
      "100": "#FDCB68",
      "200": "#FDC454",
      "300": "#FDBD40",
      "400": "#FCB62C",
      "500": "#FCAF17",
      "600": "#FCA803",
      "700": "#F2A103",
      "800": "#E89B03",
      "900": "#DE9403",
    },
    half: {
      "100": "#FDCB6880",
      "200": "#FDC45480",
      "300": "#FDBD4080",
      "400": "#FCB62C80",
      "500": "#FCAF1780",
      "600": "#FCA80380",
      "700": "#F2A10380",
      "800": "#E89B0380",
      "900": "#DE940380",
    },
    font: blackText,
  },
  yellow: {
    shade: {
      "100": "#FFE58A",
      "200": "#FFE175",
      "300": "#FFDD66",
      "400": "#FFD952",
      "500": "#FFD540",
      "600": "#FFD12E",
      "700": "#FFCD1A",
      "800": "#FAC300",
      "900": "#F0BB00",
    },
    half: {
      "100": "#FFE58A80",
      "200": "#FFE17580",
      "300": "#FFDD6680",
      "400": "#FFD95280",
      "500": "#FFD54080",
      "600": "#FFD12E80",
      "700": "#FFCD1A80",
      "800": "#FAC30080",
      "900": "#F0BB0080",
    },
    font: blackText,
  },
  warning: {
    shade: {
      "100": "#FEEADF",
      "200": "#FFD0C0",
      "300": "#FFB09F",
      "400": "#FE9286",
      "500": "#FF6160",
      "600": "#DA4652",
      "700": "#B83048",
      "800": "#941E3E",
      "900": "#7A1235",
    },
    half: {
      "100": "#FEEADF80",
      "200": "#FFD0C080",
      "300": "#FFB09F80",
      "400": "#FE928680",
      "500": "#FF616080",
      "600": "#DA465280",
      "700": "#B8304880",
      "800": "#941E3E80",
      "900": "#7A123580",
    },
    font: {
      ...blackText,
      "700": "#fff",
      "800": "#fff",
      "900": "#fff",
    },
  },
};

export default colours;
