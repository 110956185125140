import React, { useState, useMemo, useEffect } from "react";
import { getObjFromLink } from "../../app/context/helpers";
import { useProducts } from "../../app/context/products";
import CardModal from "../../app/DSL/components/elements/CardModal";
import { formatPointString } from "../../app/context/points/formatting";
import { Flex, Button, Label, Heading } from "@theme-ui/components";
import Select from "react-select";
import InputNum from "rc-input-number";
import "rc-input-number/assets/index.css";
import { useCart } from "../../app/context/cart";
import { useToasts } from "react-toast-notifications";
import { InputWrap } from "../../app/DSL/components/form/StyledInput";
import { customStyles } from "../../app/forms/Form";

export default function TransferModal({ item, open, close }) {
    const { transferWishlistItemToCart } = useCart();
    const { products } = useProducts();
    const { addToast } = useToasts();
    const [productItem, setProductItem] = useState();
    const [quantity, setQuantity] = useState(1);

    const product = useMemo(
        () =>
            item?.productLink
                ? products?.find(
                      (p) => p.id === getObjFromLink(item.productLink).id
                  )
                : {},
        [products, item]
    );

    useEffect(() => {
        if (
            (productItem === undefined || productItem === null) &&
            product?.items
        ) {
            setProductItem({
                label: product.items[0].size,
                value: product.items[0],
            });
        } else if (product === undefined || product === null) {
            setProductItem(null);
        }
    }, [product, productItem]);

    const items = useMemo(
        () =>
            product?.items
                ? product?.items.map((i) => ({ label: i.size, value: i }))
                : [],
        [product]
    );
    const closeModal = () => {
        close();
        setProductItem(null);
    };

    const transferToCart = ({ item, size, quantity }) => {
        transferWishlistItemToCart({ item, size, quantity }).then((status) => {
            const message =
                status === "added"
                    ? `${getObjFromLink(item.productLink).name} ${
                          item.size !== "fixed" ? `  (${item.size})` : ""
                      }
             added to cart`
                    : `${getObjFromLink(item.productLink).name} ${
                          item.size !== "fixed" ? `  (${item.size})` : ""
                      } already in cart`;
            closeModal();

            addToast(message, {
                appearance: status === "added" ? "success" : "warning",
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        });
    };

    return (
        <CardModal isOpen={open} close={closeModal} width="450px">
            <Heading>Confirm Transfer</Heading>
            <p>
                Confirm you would like to transfer{" "}
                {item?.productLink
                    ? getObjFromLink(item.productLink).name
                    : "this"}{" "}
                to your cart?
            </p>
            {product?.stockType === "variable" && (
                <InputWrap style={{ margin: "1em 0 2em" }}>
                    <Label>Size</Label>

                    <Select
                        styles={customStyles}
                        isClearable
                        isSearchable
                        options={items}
                        value={productItem}
                        onChange={(option) => setProductItem(option)}
                    />
                </InputWrap>
            )}

            <InputWrap style={{ margin: "1em 0 2em" }}>
                <Label>Quantity</Label>
                <InputNum
                    min={1}
                    max={10}
                    step={1}
                    value={quantity}
                    style={{ width: "100%" }}
                    onChange={(num) => {
                        setQuantity(num);
                    }}
                />
            </InputWrap>

            <h3>
                {formatPointString(
                    productItem?.value.price * (quantity > 0 ? quantity : 1)
                )}{" "}
                Points
            </h3>
            <Flex style={{ justifyContent: "space-between" }}>
                <Button onClick={closeModal} variant="outline">
                    Cancel
                </Button>
                <Button
                    onClick={() =>
                        transferToCart({
                            item,
                            size: productItem?.value.size,
                            quantity,
                        })
                    }
                >
                    Transfer to cart
                </Button>
            </Flex>
        </CardModal>
    );
}
