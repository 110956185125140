import React, { useState } from "react";
import {
  TableContainer,
  RoundButton,
  StyledLabel,
} from "../../app/DSL/components/elements/styled";
import { getObjFromLink } from "../../app/context/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputNum from "rc-input-number";
import "rc-input-number/assets/index.css";
import { InputWrap } from "../../app/DSL/components/form/StyledInput";
import { useCart } from "../../app/context/cart";
import { Image, Flex } from "@theme-ui/components";
import ConfirmationModal from "../../app/DSL/components/elements/ConfirmationModal";
import { useToasts } from "react-toast-notifications";
import MobileCard from "./MobileCard";
import ReactTooltip from "react-tooltip";

export default function CartTable() {
  const {
    cart,
    transferCartItemToWishlist,
    removeFromCart,
    updateCart,
  } = useCart();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalAction, setModalAction] = useState();
  const [modalActionType, setModalActionType] = useState();
  const [confirmItem, setConfirmItem] = useState({});

  const { addToast } = useToasts();

  const updateQty = (num, item) => {
    const otherItems = cart?.items?.filter(
      (i) => !(i.productLink === item.productLink && i.size === item.size)
    );
    updateCart([...otherItems, { ...item, quantity: num }]);
  };

  const removeItem = (item) => {
    removeFromCart(item).then(() => {
      setModalOpen(false);
      addToast(`${getObjFromLink(item.productLink).name} removed from cart`, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    });
  };

  const transferToWishlist = (item) => {
    transferCartItemToWishlist(item).then((status) => {
      const message =
        status === "added"
          ? `${getObjFromLink(item.productLink).name} ${
              item.size !== "fixed" ? `  (${item.size})` : ""
            }
             added to wishlist`
          : `${getObjFromLink(item.productLink).name} ${
              item.size !== "fixed" ? `  (${item.size})` : ""
            } already in wishlist`;
      setModalOpen(false);
      addToast(message, {
        appearance: status === "added" ? "success" : "warning",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    });
  };

  //TODO: clean up tbody
  return (
    <div>
      <div className="mobile">
        {cart.items.map((i, idx) => (
          <MobileCard
            key={idx}
            item={i}
            updateQty={updateQty}
            removeItem={removeItem}
            transferToWishlist={transferToWishlist}
            setModalOpen={setModalOpen}
            setModalAction={setModalAction}
            setModalActionType={setModalActionType}
            setConfirmItem={setConfirmItem}
          />
        ))}
      </div>
      <TableContainer className="not-mobile">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th style={{ textAlign: "center" }}>Status</th>
              <th style={{ textAlign: "center" }}>Price</th>
              <th>Quantity</th>
              <th style={{ textAlign: "center" }}>Total</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {cart?.items
              ?.sort((a, b) =>
                `${a.productLink}${a.size}` > `${b.productLink}${b.size}`
                  ? 1
                  : -1
              )
              .map((i, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      <Image
                        src={
                          i.productImageUrl
                            ? i.productImageUrl
                            : "/assets/product-placeholder.gif"
                        }
                        variant="cartPlaceholder"
                      />
                    </td>
                    <td>
                      {getObjFromLink(i.productLink).name}
                      {i.size !== "fixed" ? `  (${i.size})` : ""}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <StyledLabel
                        colour={
                          i.discontinued
                            ? "tusk"
                            : i.inStock
                            ? "lightGreen"
                            : "warning"
                        }
                        textColour="white"
                      >
                        {i.discontinued
                          ? "DISCONTINUED"
                          : i.inStock
                          ? "IN STOCK"
                          : "OUT OF STOCK"}
                      </StyledLabel>
                    </td>
                    <td style={{ textAlign: "center" }}>{i.price}</td>
                    <td>
                      <InputWrap>
                        <InputNum
                          min={1}
                          max={10}
                          step={1}
                          value={i.quantity}
                          style={{ width: "100%" }}
                          onChange={(num) => {
                            updateQty(num, i);
                          }}
                        />
                      </InputWrap>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {i.price * i.quantity}
                    </td>
                    <td>
                      <Flex>
                        <RoundButton
                          data-tip="Transfer to wishlist"
                          data-for="actionName"
                          onClick={() => {
                            setModalOpen(true);
                            setModalActionType("transfer");
                            setModalAction(() => transferToWishlist);
                            setConfirmItem(i);
                          }}
                          style={{
                            marginRight: "1em",
                          }}
                          variant="secondary"
                        >
                          <FontAwesomeIcon icon={["fas", "heart"]} size="lg" />
                        </RoundButton>
                        <RoundButton
                          data-tip="Remove from cart"
                          data-for="actionName"
                          variant="secondary"
                          onClick={() => {
                            setModalOpen(true);
                            setModalActionType("remove");
                            setModalAction(() => removeItem);
                            setConfirmItem(i);
                          }}
                        >
                          <FontAwesomeIcon icon={["fal", "times"]} size="lg" />
                        </RoundButton>
                        <ReactTooltip id="actionName" effect="solid" />
                      </Flex>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </TableContainer>
      <ConfirmationModal
        width="450px"
        isOpen={modalOpen}
        setOpen={setModalOpen}
        id={confirmItem.id}
        action={modalAction}
        actionType={modalActionType}
        item={confirmItem}
        type="cart"
      />
    </div>
  );
}
