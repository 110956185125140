export const formatUpdateProduct = (product, user) => {
  return {
    ...product,
    dateUpdated: new Date(),
    updatedBy: { id: user.id, email: user.email, name: user.displayName },
  };
};

export const formatCreateProduct = (product, user) => {
  return {
    ...product,
    dateCreated: new Date(),
    createdBy: { id: user.id, email: user.email, name: user.displayName },
  };
};
