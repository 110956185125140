import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faStar,
  faHeart,
  faShoppingCart,
  faPlus,
  faShoppingBag,
} from "@fortawesome/pro-regular-svg-icons";

library.add(faStar, faHeart, faShoppingCart, faPlus, faShoppingBag);

export { faStar, faHeart, faShoppingCart, faPlus, faShoppingBag };
