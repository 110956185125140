import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { getObjFromLink, transformCollectionSnap } from "../helpers";
import { useFirebase } from "../Firebase";
import { useAuthContext, useUserContext } from "../auth";
import { formatUpdateOrder } from "./formatting";
import { pointReport } from "../points/formatting";

const OrdersContext = createContext({});

export const OrdersProvider = ({ children }) => {
  const firebase = useFirebase();
  const [orders, setOrders] = useState();
  const { claims } = useAuthContext();
  const user = useUserContext();

  const orderedItems = useMemo(() => {
    let itemLinks = [];
    let items = [];

    if (orders) {
      orders.forEach((c) => {
        if (c.items) {
          c.items.forEach((i) => {
            if (itemLinks.includes(i.productLink)) {
              const idx = items.findIndex(
                (item) => item.productLink === i.productLink
              );
              items[idx].count = items[idx].count + 1;
            } else {
              itemLinks = [...itemLinks, i.productLink];
              items = [...items, { ...i, count: 1 }];
            }
          });
        }
      });
    }

    return items;
  }, [orders]);
  const activeOrderedItems = useMemo(
    () =>
      orders
        ? Object.entries(
            orders
              .filter((o) => o.status === "new" || o.status === "processing")
              .reduce((items, cur) => [...items, ...cur.items], [])
              .reduce(
                (obj, item) => ({
                  ...obj,
                  [item.productLink]: obj[item.productLink]
                    ? obj[item.productLink] + item.quantity
                    : item.quantity,
                }),
                {}
              )
          ).map(([productLink, count]) => ({ productLink, count }))
        : [],

    [orders]
  );
  const activeOrderedItemsWithSizes = useMemo(
    () =>
      orders
        ? Object.entries(
            orders
              .filter((o) => o.status === "new" || o.status === "processing")
              .reduce((items, cur) => [...items, ...cur.items], [])
              .reduce(
                (obj, item) => ({
                  ...obj,
                  [`${item.productLink} ${
                    item.size === "fixed" ? "" : `- ${item.size}`
                  }`]: obj[
                    `${item.productLink} ${
                      item.size === "fixed" ? "" : `- ${item.size}`
                    }`
                  ]
                    ? obj[
                        `${item.productLink} ${
                          item.size === "fixed" ? "" : `- ${item.size}`
                        }`
                      ] + item.quantity
                    : item.quantity,
                }),
                {}
              )
          ).map(([productLink, count]) => ({ productLink, count }))
        : [],

    [orders]
  );

  const outstandingOrdersTotal = useMemo(() => {
    return orders
      ? orders
          .filter((o) => o.status !== "completed" && o.status !== "cancelled")
          .reduce((arr, order) => [...arr, ...order.items], [])
          .reduce((tot, i) => (tot += i.price * i.quantity), 0)
      : 0;
  }, [orders]);

  useEffect(() => {
    const unsubscribe = user
      ? claims.isAdmin
        ? firebase
            .orders()
            .onSnapshot((snap) => setOrders(transformCollectionSnap(snap)))
        : firebase
            .orders()
            .where("userLink", "==", `${user.id}__${user.displayName}`)
            .onSnapshot((snap) => setOrders(transformCollectionSnap(snap)))
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, user, claims]);

  const getLastOrder = async () => {
    const snap = await firebase
      .orders()
      .orderBy("dateCreated", "desc")
      .limit(1)
      .get();
    const arr = transformCollectionSnap(snap);
    if (arr.length > 0) {
      return arr[0];
    } else {
      return {};
    }
  };

  const changeStatuses = ({ orderIds, status }) => {
    const batch = firebase.db.batch();

    orderIds.forEach((o) => {
      const order = orders.find((order) => order.id === o);
      if (order.status !== "cancelled" && status.value === "cancelled") {
        batch.update(firebase.user(getObjFromLink(order.userLink).id), {
          points: firebase.firestore.FieldValue.increment(order.totalPrice),
        });
        batch.set(
          firebase.db.collection("pointsLog").doc(),
          pointReport({
            reason: "order__refunded",
            numPoints: order.totalPrice,
            userLink: order.userLink,
          })
        );
      }
      batch.update(
        firebase.order(o),
        formatUpdateOrder({ status: status.value }, user)
      );
    });
    return batch.commit();
  };

  return (
    <OrdersContext.Provider
      value={{
        activeOrderedItems,
        orders,
        getLastOrder,
        changeStatuses,
        orderedItems,
        outstandingOrdersTotal,
        activeOrderedItemsWithSizes,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export const OrdersConsumer = OrdersContext.Consumer;

export const useOrders = () => useContext(OrdersContext);
