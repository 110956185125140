import React, { useState } from "react";
import PageHeaderContainer from "../../app/DSL/components/layout/PageHeaderContainer";
import { Card, Label, Input } from "@theme-ui/components";
import styled from "@emotion/styled";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import { InputWrap, IconLeft } from "../../app/DSL/components/form/StyledInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { customStyles } from "../../app/forms/Form";
import ProductsList from "./ProductsList";
import Select from "react-select";
import { primary } from "../../app/DSL/theme";

const sortOptions = [
  { label: "Default Order", value: "default" },
  { label: "Price - Highest to Lowest", value: "price-high" },
  { label: "Price - Lowest to Highest", value: "price-low" },
];

const pointOptions = [3000, 2000, 1000, 500, "Available"];

export default function Products() {
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({
    label: "Default Order",
    value: "default",
  });
  const [points, setPoints] = useState();

  return (
    <div>
      <PageHeaderContainer>Shop</PageHeaderContainer>
      <Grid>
        <Card>
          <BlockHeaderContainer title="Filter Products" />
          <InputWrap icon="left" style={{ margin: "1em 0 2em" }}>
            <Label>Search</Label>
            <IconLeft>
              <FontAwesomeIcon icon={["fas", "search"]} />
            </IconLeft>
            <Input
              placeholder="Search products"
              onChange={(e) => setSearch(e.target.value)}
            />
          </InputWrap>

          <InputWrap style={{ margin: "1em 0 2em" }}>
            <Label>Sort by</Label>

            <Select
              styles={customStyles}
              isClearable
              isSearchable
              options={sortOptions}
              value={sort}
              onChange={(option) => setSort(option)}
            />
          </InputWrap>
          <div style={{ margin: "1em 0 0" }}>
            <Label>Points</Label>
            <PointCards>
              {pointOptions.map((p, idx) => (
                <PointsCard
                  isActive={points === p}
                  onClick={() => setPoints(p)}
                  key={idx}
                >
                  <p>{p} points & under</p>
                </PointsCard>
              ))}
            </PointCards>
          </div>
        </Card>
        <Card style={{ paddingLeft: "1em", paddingRight: "1em" }}>
          <ProductsList search={search} sort={sort} numPoints={points} />
        </Card>
      </Grid>
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  column-gap: 1em;
  row-gap: 1em;
  align-items: start;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const PointCards = styled.div`
  display: grid;
  row-gap: 1em;
  column-gap: 1em;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    div:last-child {
      grid-column: 1 / 3;
    }
  }
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    div:last-child {
      grid-column: 1 / 1;
    }
  }
`;

const PointsCard = styled(Card)`
  cursor: pointer;
  padding: 1em;
  background-color: ${(props) => props.theme.colors.tusk.shade["100"]};
  color: ${(props) => props.theme.colors.tusk.font["100"]};

  p {
    text-align: center;
    margin: 0;
  }
  &:hover {
    background-color: ${(props) => props.theme.colors[primary].shade["700"]};
    color: ${(props) => props.theme.colors[primary].font["700"]};
  }
  ${(props) =>
    props.isActive &&
    `background-color:${props.theme.colors[primary].shade["500"]};
    color :${props.theme.colors[primary].font["500"]} `}
`;
