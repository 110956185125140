import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Error404 from "./Error404";
import Login from "../features/auth/Login";
import ForgotPassword from "../features/auth/ForgotPassword";
import { useAuthContext } from "./context/auth";
import Products from "../features/Products";
import Spotlight from "../features/Spotlight";
import { useMenu } from "./context/menu";
import Cart from "../features/Cart";
import Wishlist from "../features/Wishlist";
import OrderHistory from "../features/OrderHistory";
import Dashboard from "../features/AdminArea/Dashboard";
import ManageOrders from "../features/AdminArea/ManageOrders";
import ManageProducts from "../features/AdminArea/ManageProducts";
import NotApproved from "../features/NotApproved";
import NoAccess from "../features/auth/NoAccess";

const Wrapper = ({ location, children }) => {
  const { setShowAdminBar } = useMenu();
  useEffect(() => {
    setShowAdminBar(location?.pathname?.includes("admin-area"));
  }, [location, setShowAdminBar]);
  return children;
};

export default function Router({ setShowBar }) {
  const auth = useAuthContext();
  const authRoutes = [
    { path: "/spotlight", component: (props) => <Spotlight {...props} /> },
    { path: "/shop", component: (props) => <Products {...props} /> },
    { path: "/cart", component: (props) => <Cart {...props} /> },
    { path: "/wishlist", component: (props) => <Wishlist {...props} /> },
    {
      path: "/order-history",
      component: (props) => <OrderHistory {...props} />,
    },
  ];

  const adminRoutes = [
    {
      path: "/admin-area/dashboard",
      component: (props) => <Dashboard {...props} />,
    },
    {
      path: "/admin-area/orders",
      component: (props) => <ManageOrders {...props} />,
    },
    {
      path: "/admin-area/products",
      component: (props) => <ManageProducts {...props} />,
    },
  ];
  const notApprovedRoutes = [
    { path: "/spotlight", component: (props) => <NotApproved {...props} /> },
  ];

  const createRoutes = (routes) => {
    return routes.map((route, idx) => (
      <Route
        exact
        path={route.path}
        render={(props) => {
          return <Wrapper {...props}>{route.component(props)}</Wrapper>;
        }}
        key={idx}
      />
    ));
  };

  return (
    <Switch>
      <Route exact path="/" render={() => <Login />} />
      <Route exact path="/forgot-password" render={() => <ForgotPassword />} />
      {auth?.allowed &&
        auth?.claims?.isAdmin &&
        auth?.claims?.isApproved &&
        !auth?.claims?.firstLogin &&
        createRoutes(adminRoutes)}
      {auth?.allowed &&
        auth?.claims?.isApproved &&
        !auth?.claims?.firstLogin &&
        createRoutes(authRoutes)}

      {auth?.user && (
        <Route exact path="/no-access" render={() => <NoAccess />} />
      )}
      {(!auth?.claims?.isApproved || auth?.claims?.firstLogin) &&
        createRoutes(notApprovedRoutes)}
      {auth?.user && auth?.allowed !== undefined && (
        <Route
          exact
          render={(props) => (
            <Wrapper {...props}>
              <Error404 />
            </Wrapper>
          )}
        />
      )}
    </Switch>
  );
}
