import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { transformCollectionSnap } from "../helpers";
import { useFirebase } from "../Firebase";
import { useAuthContext, useUserContext } from "../auth";

import { useProducts } from "../products";

const CartsContext = createContext({});

export const CartsProvider = ({ children }) => {
  const firebase = useFirebase();
  const [carts, setCarts] = useState();
  const [wishlists, setWishlists] = useState();
  const { claims } = useAuthContext();
  const user = useUserContext();
  const { products } = useProducts();

  const items = useMemo(() => {
    let itemLinks = [];
    let items = [];

    if (carts) {
      carts.forEach((c) => {
        if (c.items) {
          c.items.forEach((i) => {
            if (itemLinks.includes(i.productLink)) {
              const idx = items.findIndex(
                (item) => item.productLink === i.productLink
              );
              items[idx].cartCount = items[idx].cartCount + 1;
            } else {
              itemLinks = [...itemLinks, i.productLink];
              items = [...items, { ...i, cartCount: 1 }];
            }
          });
        }
      });
    }
    if (wishlists) {
      wishlists.forEach((c) => {
        if (c.items) {
          c.items.forEach((i) => {
            if (itemLinks.includes(i.productLink)) {
              const idx = items.findIndex(
                (item) => item.productLink === i.productLink
              );
              items[idx].wishlistCount = items[idx].wishlistCount + 1;
            } else {
              itemLinks = [...itemLinks, i.productLink];
              items = [...items, { ...i, wishlistCount: 1 }];
            }
          });
        }
      });
    }

    return items;
  }, [carts, wishlists]);

  const wishlistsTotal = useMemo(() => {
    if (wishlists && products) {
      return wishlists
        ?.reduce((arr, cart) => [...arr, ...cart.items], [])
        .map((i) => {
          const prod = products?.find(
            (product) => i.productLink === `${product.id}__${product.name}`
          );
          if (prod && !prod.isArchived) {
            const item = prod?.items[0];
            if (item && item.inStock) {
              return item.price;
            } else return 0;
          } else return 0;
        })
        .reduce((tot, p) => (tot += p), 0);
    } else {
      return 0;
    }
  }, [wishlists, products]);

  const cartsTotal = useMemo(() => {
    if (carts && products) {
      return carts
        ?.reduce((arr, cart) => [...arr, ...cart.items], [])
        .map((i) => {
          const prod = products?.find(
            (product) => i.productLink === `${product.id}__${product.name}`
          );
          if (prod && !prod.isArchived) {
            const item = prod?.items?.find((it) => i?.size === it?.size);
            if (item && item.inStock) {
              return item.price;
            } else return 0;
          } else return 0;
        })
        .reduce((tot, p) => (tot += p), 0);
    } else {
      return 0;
    }
  }, [carts, products]);

  useEffect(() => {
    const unsubscribe =
      user && claims.isAdmin
        ? firebase.carts().onSnapshot((snap) => {
            const carts = transformCollectionSnap(snap);
            setCarts(carts);
          })
        : () => console.log("not logged in/ not Admin");
    return () => unsubscribe();
  }, [firebase, user, claims]);
  useEffect(() => {
    const unsubscribe =
      user && claims.isAdmin
        ? firebase.wishlists().onSnapshot((snap) => {
            setWishlists(transformCollectionSnap(snap));
          })
        : () => console.log("not logged in/ not Admin");
    return () => unsubscribe();
  }, [firebase, user, claims]);

  return (
    <CartsContext.Provider
      value={{ carts, wishlists, items, wishlistsTotal, cartsTotal }}
    >
      {children}
    </CartsContext.Provider>
  );
};

export const CartsConsumer = CartsContext.Consumer;

export const useCarts = () => useContext(CartsContext);
