import React from "react";
import BlockHeaderContainer from "../../app/DSL/components/layout/BlockHeaderContainer";
import styled from "@emotion/styled";
import StatusLabel from "../../app/DSL/components/elements/StatusLabel";
import { format } from "date-fns";

import MobileBreakdownCard from "./MobileBreakdownCard";

export default function MobileBreakdown({ order, admin }) {
  return (
    <div>
      <div style={{ color: "black" }}>
        <BlockHeaderContainer
          title={`Order #${order?.id.slice(0, 6).toUpperCase()} ${
            admin ? `- ${order.placedBy.name}` : ""
          }`}
        />
      </div>
      {order && (
        <Grid>
          <div>
            <div className="not-mobile">
              <h3>Order Status</h3>
              <StatusLabel status={order.status} />
            </div>
            <div>
              <h3>Total Price</h3>
              <p>{order.totalPrice}</p>
            </div>
            <div>
              <h3>Order Placed</h3>
              <p>
                {order?.dateCreated
                  ? format(order?.dateCreated.toDate(), "dd MMMM yyyy")
                  : "-"}
              </p>
            </div>
            <div>
              <h3>Delivery Date</h3>
              <p>
                {order?.deliveryDate
                  ? format(order?.deliveryDate.toDate(), "dd MMMM yyyy")
                  : "-"}
              </p>
            </div>
          </div>
          <Divider />
          <CardGrid>
            {order?.items?.map((i, idx) => {
              return <MobileBreakdownCard item={i} />;
            })}
          </CardGrid>
        </Grid>
      )}
    </div>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 3fr;
  column-gap: 1em;
  * {
    color: black;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1px auto;
    row-gap: 1em;

    .not-mobile {
      display: none !important;
    }
    > div:first-of-type {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 1em;
    }
  }
  @media (max-width: 576px) {
    > div:first-of-type {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 1em;
    }
  }
`;
const Divider = styled.div`
  height: 100%;
  background: ${(props) => props.theme.colors.tusk.shade["500"]};
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1em;
  margin-bottom: 1em;
`;
