import styled from "@emotion/styled";
import { Button } from "@theme-ui/components";
//TODO: move to styled.js
export const FloatingButton = styled(Button)`
  position: fixed;
  bottom: 2em;
  opacity: ${(props) => (props.show ? "1" : "0")};
  pointer-events: ${(props) => (props.show ? "pointer" : "none")};
  transition: opacity ease 0.3s;
`;
