import * as firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import config from "./config";

// Initialize Firebase
firebase.initializeApp(config);

const auth = firebase.auth();
const db = firebase.firestore();
const firestore = firebase.firestore;
const { arrayUnion, arrayRemove } = firebase.firestore.FieldValue;

const Firebase = {
  /////////////////////// AUTH ///////////////////////////////
  auth,

  loginWithEmail: (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  },
  signOut: () => {
    return auth.signOut();
  },
  checkUserAuth: (user) => {
    return auth.onAuthStateChanged(user);
  },

  updatePassword: (password) => {
    return auth.currentUser.updatePassword(password);
  },
  sendForgotPasswordEmail: (email) => {
    return auth.sendPasswordResetEmail(email);
  },

  /////////////////////// FIRESTORE ///////////////////////////////
  db,
  firestore,
  arrayUnion,
  arrayRemove,
  //----------------- USERS -----------------

  user: (userId) => {
    return db.collection("users").doc(userId);
  },

  users: () => {
    return db.collection("users");
  },

  //----------------- PRODUCTS -----------------
  products: () => {
    return db.collection("shopProducts");
  },
  product: (id) => {
    return db.collection("shopProducts").doc(id);
  },

  //----------------- CARTS -----------------
  carts: () => {
    return db.collection("shopCarts");
  },
  cart: (id) => {
    //uses user id
    return db.collection("shopCarts").doc(id);
  },
  //----------------- WISHLISTS -----------------
  wishlists: () => {
    return db.collection("shopWishlists");
  },
  wishlist: (id) => {
    //uses user id
    return db.collection("shopWishlists").doc(id);
  },

  //----------------- ORDERS -----------------
  orders: () => {
    return db.collection("shopOrders");
  },
  order: (id) => {
    return db.collection("shopOrders").doc(id);
  },

  //----------------- SLIDERS -----------------

  sliders: () => {
    return db.collection("dashboardSliders");
  },

  slider: (id) => {
    return db.collection("dashboardSliders").doc(id);
  },

  //----------------- SETTINGS -----------------

  setting: (name) => {
    return db.collection("settings").doc(name);
  },
};

export default Firebase;
