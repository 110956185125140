import React from "react";
import CardModal from "../../../app/DSL/components/elements/CardModal";
import OrderBreakdown from "../../OrderHistory/OrderBreakdown";
import { Flex, Button } from "@theme-ui/components";
import MobileBreakdown from "../../OrderHistory/MobileBreakdown";

export default function ViewModal({ open, close, order, setModalType }) {
  return (
    <CardModal isOpen={open} close={close}>
      <div className="not-mobile">
        <OrderBreakdown order={order} admin />
      </div>
      <div className="mobile">
        <MobileBreakdown order={order} admin />
      </div>
      <Flex style={{ justifyContent: "space-between" }}>
        <Button variant="outline" onClick={() => close()}>
          Close
        </Button>
        <Button onClick={() => setModalType("status")}>Update status</Button>
      </Flex>
    </CardModal>
  );
}
