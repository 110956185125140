import React from "react";
import ReactModal from "react-modal";
import { Button, Card, Heading } from "@theme-ui/components";
import { Flex } from "theme-ui";
import { getObjFromLink } from "../../../context/helpers";

ReactModal.setAppElement("#root");

export default function ConfirmationModal({
  isOpen,
  setOpen,
  actionType,
  name,
  action,
  item,
  width,
}) {
  ReactModal.setAppElement("#root");

  const formattedName = name
    ? name
    : item?.productLink
    ? getObjFromLink(item.productLink).name
    : "that";

  return (
    <ReactModal isOpen={isOpen} overlayClassName="overlay" className="content">
      <Card style={{ maxWidth: width, margin: "auto" }}>
        <Heading>Confirm {actionType}</Heading>
        {actionType !== "details" && (
          <div>
            <p style={{ margin: "2em 0" }}>
              Are you sure you would like to {actionType} {formattedName}?
            </p>
            <Flex sx={{ justifyContent: "space-between" }}>
              <Button variant="outline" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  action(item);
                }}
              >
                Confirm
              </Button>
            </Flex>
          </div>
        )}
      </Card>
    </ReactModal>
  );
}
