import React from "react";
import styled from "@emotion/styled";
import { Card, Button } from "@theme-ui/components";
import { formatPointString } from "../../app/context/points/formatting";
import { primary } from "../../app/DSL/theme";

export default function FeaturedCard({ product, admin, action, buttonText }) {
  return (
    <FeatCard admin={admin}>
      <div
        style={{
          backgroundImage: `url(${product.productImageUrl})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className="divider"></div>
      <div className="description">
        <h3>{product.name}</h3>
        <p>
          {product.stockType === "variable" && "From "}
          {product?.minPrice && formatPointString(product.minPrice)} Points
        </p>
        <Button style={{ width: "100%" }} onClick={action}>
          {buttonText}
        </Button>
      </div>
    </FeatCard>
  );
}

const FeatCard = styled(Card)`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  column-gap: 1em;
  row-gap: 1em;
  padding: 1em;
  margin-bottom: ${(props) => (props.admin ? "1em" : 0)};
  height: 100%;
  .divider {
    background: ${(props) => props.theme.colors.tusk.shade["500"]};
  }

  .description {
    display: grid;
    grid-template-rows: 1fr auto 45px;
    p {
      color: ${(props) => props.theme.colors[primary].shade["500"]};
      align-self: end;
    }
  }

  @media (max-width: 568px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1px 1fr;
  }
`;
