import React, { createContext, useContext, useState, useEffect } from "react";
import { useFirebase } from "../Firebase";
import { useUserContext } from "../auth";
import { transformDocSnap } from "../helpers";

const StatsContext = createContext({});

export const StatsProvider = ({ children }) => {
  const firebase = useFirebase();
  const [stats, setStats] = useState({});
  const [logins, setLogins] = useState();
  const user = useUserContext();

  useEffect(() => {
    const unsubscribe = user
      ? firebase.setting("shopLogins").onSnapshot((snap) => {
          const logins = transformDocSnap(snap);
          setLogins(logins);
          const today = new Date();
          const todaysLogins =
            logins[
              `${today.getDate()}-${
                today.getMonth() + 1
              }-${today.getFullYear()}`
            ];
          setStats((prev) => ({ ...prev, todaysLogins }));
        })
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, user]);

  return (
    <StatsContext.Provider value={{ stats, logins }}>
      {children}
    </StatsContext.Provider>
  );
};

export const StatsConsumer = StatsContext.Consumer;

export const useStats = () => useContext(StatsContext);
