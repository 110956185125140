export const standardNavItems = [
  {
    title: "Spotlight",
    link: "/spotlight",
    icon: ["fal", "lightbulb-on"],
  },
  {
    title: "Shop",
    link: "/shop",
    icon: ["fal", "store-alt"],
  },
  {
    title: "Order History",
    link: "/order-history",
    icon: ["fal", "history"],
  },
];

export const notApprovedNavItems = [
  {
    title: "Spotlight",
    link: "/spotlight",
    icon: ["fal", "lightbulb-on"],
  },
];

export const adminNavItems = [
  {
    title: "Dashboard",
    link: "/admin-area/dashboard",
    icon: ["fal", "th"],
  },
  {
    title: "Orders",
    link: "/admin-area/orders",
    icon: ["fal", "history"],
  },
  {
    title: "Products",
    link: "/admin-area/products",
    icon: ["fal", "boxes"],
  },
];
