import React, { useMemo, useCallback } from "react";
import { useProducts } from "../../../app/context/products";
import { AspectImage } from "@theme-ui/components";
import { useToasts } from "react-toast-notifications";
import { Ring } from "react-awesome-spinners";
import colours from "../../../app/DSL/styles/colours";
import { primary } from "../../../app/DSL/theme";
import {
  ActionsTableContainer,
  RoundButton,
} from "../../../app/DSL/components/elements/styled";
import Table from "../../../app/DSL/components/elements/Table";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function FeaturedProducts() {
  const { products, updateProduct } = useProducts();
  const featuredProducts = useMemo(
    () =>
      products
        ? products.filter((p) => !p.isArchived).filter((p) => p.isFeatured)
        : [],
    [products]
  );
  const { addToast } = useToasts();

  const removeFeatured = useCallback(
    (id) => {
      updateProduct(id, { isFeatured: false }).then(() =>
        addToast("Product removed from featured list", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 3000,
        })
      );
    },
    [updateProduct, addToast]
  );

  const columns = useMemo(
    () => [
      {
        Header: "",
        id: "img",
        accessor: (p) => (
          <div style={{ width: "50px" }}>
            <AspectImage
              ratio={1 / 1}
              src={
                p.productImageUrl
                  ? p.productImageUrl
                  : "/assets/product-placeholder.gif"
              }
            />
          </div>
        ),
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: "",
        id: "name",
        accessor: (p) => p.name,
        Cell: (cell) => <h4>{cell.row.original.name}</h4>,
        filter: "fuzzyText",
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: "",
        id: "price",
        accessor: (p) => p.minPrice,
        Cell: (cell) => (
          <p>
            {cell.row.original.stockType === "variable" && "From "}
            {cell.row.original.minPrice} Points
          </p>
        ),
        filter: "fuzzyText",
        disableFilters: true,
        disableSortBy: true,
      },

      {
        Header: "",
        id: "actions",
        accessor: (u) => u.id,
        Cell: (cell) => {
          return (
            <span>
              <RoundButton
                data-tip="View Product"
                data-for="actionName"
                variant="secondary"
                onClick={() => {
                  removeFeatured(cell.row.original.id);
                }}
              >
                <FontAwesomeIcon icon={["fal", "times"]} />
              </RoundButton>
              <ReactTooltip id="actionName" effect="solid" />
            </span>
          );
        },
        className: "actions",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [removeFeatured]
  );
  return products ? (
    <div style={{ marginBottom: "3em" }}>
      {featuredProducts.length > 0 && (
        <div>
          <h4 style={{ marginBottom: 0 }}>Products shown on spotlight</h4>
          <ActionsTableContainer numActions={1}>
            <Table
              emptyMsg="No products"
              columns={columns}
              data={featuredProducts.slice(0, 3)}
              defaultRows={5}
              loading={products === undefined}
              pagination={false}
              filterPlaceholder={false}
            />
          </ActionsTableContainer>
        </div>
      )}

      {featuredProducts.length > 3 && (
        <div>
          <h4 style={{ marginBottom: 0 }}>Other featured products</h4>
          <ActionsTableContainer numActions={1}>
            <Table
              emptyMsg="No products"
              columns={columns}
              data={featuredProducts.slice(3, featuredProducts.length)}
              defaultRows={5}
              loading={products === undefined}
              pagination={false}
              filterPlaceholder={false}
            />
          </ActionsTableContainer>
        </div>
      )}
    </div>
  ) : (
    <div style={{ textAlign: "center" }}>
      <Ring color={colours[primary].shade["500"]} />
    </div>
  );
}
