import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useUserContext, useAuthContext } from "../auth";
import { useFirebase } from "../Firebase";
import { formatProduct, formatWishlistProduct } from "./formatting";
import { transformDocSnap, getObjFromLink } from "../helpers";
import { useProducts } from "../products";
import { formatOrder } from "../orders/formatting";
import { pointReport } from "../points/formatting";
import { useOrders } from "../orders";

const CartContext = createContext({});

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState();
  const [wishlist, setWishlist] = useState();
  const user = useUserContext();
  const { userId } = useAuthContext();
  const firebase = useFirebase();
  const { products } = useProducts();
  const { getLastOrder } = useOrders();

  const formatCartItems = useCallback(
    ({ items }) => {
      return (
        products &&
        (items
          ? items
              .map((i) => {
                const prod = products?.find(
                  (product) =>
                    i.productLink === `${product.id}__${product.name}`
                );
                if (prod) {
                  const item = prod?.items?.find((it) => it?.size === i?.size);
                  if (item) {
                    return {
                      ...i,
                      price: item.price,
                      inStock: item.inStock,
                      discontinued: prod.isArchived === true,
                    };
                  } else return item;
                } else return prod;
              })
              .filter((i) => i !== undefined)
          : [])
      );
    },
    [products]
  );
  const formatWishlistItems = useCallback(
    ({ items }) => {
      return (
        products &&
        (items
          ? items
              .map((i) => {
                const prod = products?.find(
                  (product) =>
                    i.productLink === `${product.id}__${product.name}`
                );
                if (prod) {
                  return {
                    ...i,
                    inStock: prod.inStock,
                    stockType: prod.stockType,
                    price: prod.items[0].price,
                    discontinued: prod.isArchived === true,
                  };
                } else return prod;
              })
              .filter((i) => i !== undefined)
          : [])
      );
    },
    [products]
  );

  const availablePoints = useMemo(() => {
    const cartPoints = cart?.items
      ? cart?.items.reduce((tot, i) => (tot += i.price ? i.price : 0), 0)
      : 0;
    return user?.points - cartPoints;
  }, [user, cart]);

  const cartItemIds = useMemo(
    () => cart?.items?.map((i) => getObjFromLink(i.productLink).id),
    [cart]
  );

  const wishlistItemIds = useMemo(
    () => wishlist?.items?.map((i) => getObjFromLink(i.productLink).id),
    [wishlist]
  );

  useEffect(() => {
    const unsubscribe = userId
      ? firebase.cart(userId).onSnapshot((snap) => {
          const items = formatCartItems(transformDocSnap(snap));
          if (items) setCart({ items });
        })
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, userId, formatCartItems]);

  useEffect(() => {
    const unsubscribe = userId
      ? firebase.wishlist(userId).onSnapshot((snap) => {
          const items = formatWishlistItems(transformDocSnap(snap));
          if (items)
            setWishlist({
              items,
            });
        })
      : () => console.log("not logged in");
    return () => unsubscribe();
  }, [firebase, userId, formatWishlistItems]);

  const addToCart = async (product) => {
    const formattedProduct = product.productLink
      ? product
      : formatProduct(product);

    const itemInCart = cart?.items?.find(
      (i) =>
        i.productLink === formattedProduct.productLink &&
        i.size === formattedProduct.size
    );
    if (!itemInCart) {
      return firebase
        .cart(user.id)
        .set(
          {
            items: firebase.arrayUnion(formattedProduct),
            dateUpdated: new Date(),
          },
          { merge: true }
        )
        .then(() => "added");
    } else {
      return "already in cart";
    }
  };

  const updateCart = async (items) => {
    return firebase
      .cart(user.id)
      .set(
        {
          items: items,
          dateUpdated: new Date(),
        },
        { merge: true }
      )
      .then(() => "added");
  };

  const removeFromCart = (product) => {
    const formattedProduct = product.productLink
      ? product
      : formatProduct(product);

    const itemsInCart = cart.items?.filter(
      (i) =>
        !(
          i.productLink === formattedProduct.productLink &&
          i.size === formattedProduct.size
        )
    );
    if (itemsInCart) {
      return firebase.cart(user.id).update({
        items: itemsInCart,
        dateUpdated: new Date(),
      });
    }
  };

  const addToWishlist = async (product) => {
    const formattedProduct = product.productLink
      ? product
      : formatWishlistProduct(product);

    const itemInWishlist = wishlist.items?.find(
      (i) => i.productLink === formattedProduct.productLink
    );
    if (!itemInWishlist) {
      return firebase
        .wishlist(user.id)
        .set(
          {
            items: firebase.arrayUnion(formattedProduct),
            dateUpdated: new Date(),
          },
          { merge: true }
        )
        .then(() => "added");
    } else {
      return "already in wishlist";
    }
  };

  const removeFromWishlist = (product) => {
    const formattedProduct = product.productLink
      ? product
      : formatWishlistProduct(product);

    const itemsInWishlist = wishlist.items?.filter(
      (i) => !(i.productLink === formattedProduct.productLink)
    );

    if (itemsInWishlist) {
      return firebase.wishlist(user.id).update({
        items: itemsInWishlist,
        dateUpdated: new Date(),
      });
    }
  };

  const clearCart = () => {
    firebase.cart(user.id).delete();
  };

  const clearWishlist = () => {
    firebase.wishlist(user.id).delete();
  };

  const transferCartItemToWishlist = (item) => {
    return removeFromCart(item).then(() => {
      return addToWishlist(item);
    });
  };

  const transferWishlistItemToCart = ({ item, size, quantity }) => {
    return removeFromWishlist(item).then(() => {
      return addToCart({ ...item, size, quantity });
    });
  };

  const placeOrder = async ({ items, totalPrice }) => {
    const reason = "order__placed";
    const lastOrder = await getLastOrder();

    const batch = firebase.db.batch();
    await batch.set(
      firebase.orders().doc(),
      formatOrder({ items, user, lastOrder, totalPrice })
    );
    await batch.update(firebase.user(user.id), {
      points: firebase.firestore.FieldValue.increment(-totalPrice),
    });
    await batch.set(
      firebase.db.collection("pointsLog").doc(),
      pointReport({
        reason,
        numPoints: totalPrice,
        userLink: `${user.id}__${user.displayName}`,
      })
    );
    return await batch.commit().then(() => clearCart());
  };

  return (
    <CartContext.Provider
      value={{
        addToCart,
        cart,
        wishlist,
        removeFromCart,
        clearCart,
        addToWishlist,
        removeFromWishlist,
        clearWishlist,
        cartItemIds,
        wishlistItemIds,
        availablePoints,
        updateCart,
        transferCartItemToWishlist,
        transferWishlistItemToCart,
        placeOrder,
        formatCartItems,
        formatWishlistItems,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const CartConsumer = CartContext.Consumer;

export const useCart = () => useContext(CartContext);
