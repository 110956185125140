/** @jsx jsx */
import { jsx } from "theme-ui";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { useAuthContext } from "../../../context/auth";
import { primary } from "../../theme";
import { useMenu } from "../../../context/menu";
import { notApprovedNavItems, standardNavItems } from "./navItems";
import { useMemo } from "react";
import { Image } from "@theme-ui/components";
const SideNav = () => {
  const { claims, allowed } = useAuthContext();
  const { showAdminBar } = useMenu();
  const navItems = useMemo(
    () =>
      claims?.isApproved && !claims?.firstLogin
        ? standardNavItems
        : notApprovedNavItems,
    [claims]
  );

  return allowed ? (
    <NavBlock className="sidenav">
      <Nav>
        <BoxButton type="logo">
          <img src="/ZBLogo.svg" alt="zb" />
        </BoxButton>
        <TopOptions>
          {navItems.map((i, idx) => (
            <IconBox key={idx}>
              <BoxLink to={i.link}>
                <FontAwesomeIcon icon={i.icon} size="2x" />
                <p>{i.title}</p>
              </BoxLink>
            </IconBox>
          ))}
        </TopOptions>
        <BotOptions>
          {/* TODO: add env variables */}
          <a
            href={
              claims?.isAdmin
                ? "https://admin.flexisite.co.za/reconise-landing-page/wp-content/uploads/sites/6/2020/05/Reconise-Shop-Admin-User-Manual.pdf"
                : "https://admin.flexisite.co.za/reconise-landing-page/wp-content/uploads/sites/6/2020/05/Reconise-Shop-User-Manual.pdf"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <BoxButton>
              <FontAwesomeIcon icon={["fas", "question-circle"]} size="2x" />
              <p>Need help?</p>
            </BoxButton>
          </a>
          {claims?.isAdmin && (
            <IconBox>
              <BoxLink
                to="/admin-area/dashboard"
                className={showAdminBar && "active"}
              >
                <FontAwesomeIcon icon={["fas", "lock"]} size="2x" />
                <p>Admin Area</p>
              </BoxLink>
            </IconBox>
          )}
          <IconBox>
            <a href={process.env.REACT_APP_HQ_LINK}>
              <BoxButton>
                <Image src="/assets/HQ-icon.png" />
                <p>HQ</p>
              </BoxButton>
            </a>
          </IconBox>
        </BotOptions>
      </Nav>
    </NavBlock>
  ) : (
    <NavBlock>
      <Nav>
        <BoxButton>
          <img src="/ZBLogo.svg" alt="zb" />
        </BoxButton>
      </Nav>
    </NavBlock>
  );
};

export default SideNav;

const NavBlock = styled.div`
  height: 100%;
  background-color: ${(props) => props.theme.colors[primary].shade["500"]};
`;

const Nav = styled.nav`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 75px;
  display: grid;
  grid-template-rows: 75px 1fr 1fr;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.1);
`;

const TopOptions = styled.div`
  display: grid;
  align-content: start;
`;

const BotOptions = styled.div`
  display: grid;
  align-items: end;
  align-self: end;
  margin-bottom: 1em;
`;

const IconBox = styled.div`
  a[aria-current="page"] {
    background: ${(props) => props.theme.colors[primary].shade["700"]};
  }
  cursor: pointer;
  height: 75px;
  margin-top: 1em;
`;

const BoxLink = styled(NavLink)`
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.type === "logo" && props.theme.colors[primary].shade["800"]};
  text-align: center;
  display: grid;
  * {
    color: ${(props) => props.theme.colors[primary].font["500"]};
    justify-self: center;

    margin: 0;
  }
  svg {
    align-self: end;
    margin-bottom: 5px;
  }
  p {
    align-self: start;
    margin-top: 2px;
    font-size: 0.7em;
  }
`;

const BoxButton = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.type === "logo" && props.theme.colors[primary].shade["800"]};
  text-align: center;
  display: grid;
  cursor: pointer;
  * {
    color: ${(props) => props.theme.colors[primary].font["500"]};
    justify-self: center;

    margin: 0;
  }
  svg {
    align-self: end;
    margin-bottom: 5px;
  }
  p {
    align-self: start;
    margin-top: 2px;
    font-size: 0.7em;
  }
  img {
    align-self: center;
    margin-top: 2px;
    width: 40px;
  }
`;
